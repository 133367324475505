import React from "react";
import Select from "react-select";
import { createThemeSelect } from "../../constant/constant";

function SelectField({ ...otherProps }) {
  return (
    <Select
      theme={createThemeSelect}
      classNamePrefix="mySelect"
      {...otherProps}
    />
  );
}

export default SelectField;
