import React from "react";
export const LocationIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99799 5.61777H9.99788C8.80667 5.61777 7.66426 6.09096 6.82196 6.9333C5.97967 7.77563 5.50645 8.91799 5.50645 10.1092C5.50645 11.3004 5.97963 12.4428 6.82197 13.2851C7.6643 14.1274 8.80667 14.6006 9.99788 14.6006C11.1891 14.6006 12.3315 14.1275 13.1738 13.2851C14.0161 12.4428 14.4893 11.3004 14.4893 10.1092V10.1091C14.488 8.91826 14.0144 7.77674 13.1725 6.9346L13.1725 6.93458C12.3304 6.09261 11.1888 5.6191 9.99799 5.61777ZM7.99852 12.1086L7.9985 12.1086C7.46817 11.5784 7.17026 10.8592 7.17026 10.1092C7.17026 9.35924 7.46817 8.64002 7.9985 8.10985L7.99852 8.10983C8.52869 7.5795 9.24791 7.28159 9.99788 7.28159C10.7479 7.28159 11.4671 7.5795 11.9972 8.10983L11.9973 8.10985C12.5276 8.64 12.8255 9.35917 12.8255 10.1091C12.8247 10.8588 12.5265 11.5776 11.9963 12.1076L11.9963 12.1076C11.4662 12.6378 10.7475 12.9361 9.99778 12.9368C9.24785 12.9368 8.52867 12.6389 7.99852 12.1086Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.2"
      />
      <path
        d="M4.73902 4.73687C5.92548 3.55168 7.47787 2.80225 9.14401 2.6101C10.8101 2.41795 12.4921 2.79452 13.9171 3.67858L13.9698 3.5936L13.9171 3.67858C15.3423 4.56263 16.4269 5.90241 16.9948 7.48043L16.9948 7.48044C17.5629 9.05846 17.581 10.7821 17.0463 12.3716L17.0266 12.4301L17.0266 12.4301C16.6612 13.4946 16.0559 14.4612 15.2569 15.2547L15.2567 15.2549L9.99788 20.5138L4.73906 15.2549L4.73903 15.2549C3.81549 14.332 3.15116 13.1821 2.81313 11.9211L2.81313 11.9211C2.47492 10.6598 2.47492 9.33198 2.81313 8.07088L2.81313 8.07086C3.15117 6.80963 3.81548 5.65975 4.73902 4.73687ZM4.73902 4.73687L4.66835 4.66612L4.73902 4.73687ZM16.3586 3.48691C14.9166 2.08009 13.0476 1.19061 11.0443 0.959857C9.00638 0.724961 6.94879 1.18567 5.20519 2.2668L5.20518 2.2668C3.46178 3.34793 2.13471 4.98642 1.43908 6.91634L1.43907 6.91635C0.74366 8.84621 0.72035 10.9546 1.37331 12.8992C1.81754 14.2315 2.56801 15.441 3.56428 16.4309C3.56434 16.431 3.5644 16.431 3.56447 16.4311L9.40986 22.2777L9.48058 22.207L9.40987 22.2777C9.56585 22.4337 9.77736 22.5214 9.99797 22.5214C10.2186 22.5214 10.4301 22.4337 10.5861 22.2777L16.4334 16.4309C16.4334 16.4309 16.4334 16.4309 16.4334 16.4309C17.5634 15.3013 18.3761 13.8943 18.7898 12.3511C19.2034 10.8079 19.2034 9.18292 18.7898 7.63975C18.3762 6.09657 17.5634 4.68948 16.4334 3.56009L16.3593 3.48609L16.3586 3.48691Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.2"
      />
    </svg>
  );
};
