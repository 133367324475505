import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import Cookies from 'js-cookie';
import { useState } from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import {
  business_units,
  businessUnitName,
  firstName,
  lastName,
  selected_business_unit
} from "../../config/localStorage";
import { colorPalette } from "../../constant/constant";
import {
  createBuOptions,
  getPath,
  isAdminUser,
  setUserCookies,
  setUserLocalStorage,
  truncateString,
} from "../../helper/commonHelper";
import { getActiveSnop, getConfiguration } from "../../utils/ApiHandler";
import { SNOP } from "../../utils/Constants";
import EndPoint from "../../utils/EndPoints";

function MultiAvatar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const buOption = createBuOptions(business_units);
  const isAdmin = isAdminUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleBuOption = async (option) => {
    let value = option.value;
    const selected_business_unit = {
      business_unit_id: option.value,
      business_unit_name: option.label,
    };
    localStorage.setItem(
      "selected_business_unit",
      JSON.stringify(selected_business_unit)
    );
    let user = JSON.parse(Cookies.get("user") || null);
    let user_metadata = JSON.parse(localStorage.getItem("user") || null);
    user = { ...user, ...user_metadata };
    if (!user) return;
    const newUser = setUserLocalStorage(user, value);
    const permissions = newUser?.permissions;
    const roles = newUser?.roles;
    if (!permissions || !roles) return;
    const path = getPath(permissions, roles);
    if (user.tenant_id && selected_business_unit?.business_unit_id) {
      const configurationResult = await getConfiguration(
        user.access,
        user.tenant_id,
        selected_business_unit?.business_unit_id
      );
      let isSandOpPermission = permissions.includes(SNOP[0]);
      if (!isSandOpPermission) {
        window.location.replace(path.path);
        return;
      }
      if (configurationResult?.status === 200) {
        await getActiveSnop(
          user.access,
          user.tenant_id,
          selected_business_unit?.business_unit_id
        );
        setUserCookies(newUser);
        window.location.replace(path.path);
      }
    } else {
      setUserCookies(newUser);
      window.location.replace(path.path);
    }

  };

  const handleClickMyProfile = () => {
    if (isAdmin) return true;
    navigate(EndPoint.MY_PROFILE);
  };

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        onClick={(e) => {
          if (!isAdmin) handleClick(e);
        }}
        sx={{
          cursor: isAdmin ? "auto" : "pointer",
        }}
      >
        <Avatar
          name={`${firstName} ${lastName}`}
          size="40"
          textSizeRatio={1}
          textMarginRatio={0.25}
          round
          className="me-2"
        />
        <div className="header-logout-user">
          <span className="header-user-name">{`${truncateString(firstName + " " + lastName, 20)
            }`}</span>
          <span>
            {!isAdmin && `${businessUnitName}`}
            {/* {businessUnitName && "|"} {userRole[0]}{" "} */}
          </span>
        </div>
        {!isAdmin && <KeyboardArrowDownIcon fontSize="medium" />}
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            maxHeight: 150,
          }}
        >
          <List
            disablePadding
            sx={{
              minWidth: 140,
            }}
          >
            <ListItem disablePadding onClick={handleClickMyProfile}>
              <ListItemButton>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.01rem",
                      fontWeight: "500",
                      color: colorPalette.grey,
                    }}
                  >
                    My Profile
                  </Typography>
                  <ManageAccountsOutlinedIcon
                    sx={{
                      color: colorPalette.grey,
                    }}
                  />
                </Stack>
              </ListItemButton>
            </ListItem>
            <Divider />
          </List>
          <Stack>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                px: 2,
                pt: 1,
                pb: 0,
              }}
            >
              BU's List
            </Typography>
            <List sx={{ p: 0 }}>
              {buOption.map((option) => {
                return (
                  <>
                    <ListItem
                      disablePadding
                      key={option.value}
                      onClick={() => handleBuOption(option)}
                    >
                      <ListItemButton sx={{ pl: 3 }}>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          sx={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.01rem",
                              fontWeight: "500",
                              color: colorPalette.grey,
                            }}
                          >
                            {option.label}
                          </Typography>
                          {selected_business_unit?.business_unit_id ===
                            option.value && (
                              <CheckOutlinedIcon
                                sx={{
                                  color: colorPalette.green,
                                }}
                              />
                            )}
                        </Stack>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            </List>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export default MultiAvatar;
