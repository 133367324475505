import React from "react";
import "./input-field.scss";

const InputField = (props) => {
  return (
    // <!-- Email input -->
    <div
      className={`input-field ${
        props?.containerClassName ? props?.containerClassName : ""
      }`}
    >
      <div className="login-box">
        <div className="group">
          <input
            title=""
            type={props.type}
            required="required"
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            className={props.className}
            onKeyDown={props.onKeyDown}
          />
          <span className="highlight"></span>
          <span className="bar"></span>
          <label>{props.label}</label>
          {props.icon}
        </div>
      </div>
      <div className="helper-text">{props?.helperText}</div>
    </div>
  );
};

export default InputField;
