// import "./App.css";
import { createBrowserHistory } from "history";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Network from "./components/Network";
import Routing from "./routers/Routing";
import "./style/sass/main.scss";


const defaultHistory = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <BrowserRouter history={defaultHistory} basename="scai">
        <Routing />
        <ToastContainer />
        <Network />
      </BrowserRouter>
    </div>
  );
}

export default App;
