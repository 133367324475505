import { createBrowserHistory } from "history";
import Cookies from 'js-cookie';
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MicroFrontend from "../../MicroFrontend";
import Layout from "../../components/Layouts/Layout";
import "../style.scss";
const defaultHistory = createBrowserHistory();

const AdminFrontend = () => {
  const { REACT_APP_ADMIN_HOST: adminHost } = process.env;
  const user = JSON.parse(Cookies.get("user") || null);
  let navigate = useNavigate();

  function AdminModule({ history }) {
    return <MicroFrontend history={history} host={adminHost} name="admin" />;
  }

  useEffect(() => {
    if (isEmpty(user)) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout>
      <div id="admin">
        <div>
          <AdminModule history={defaultHistory} />
        </div>
      </div>
    </Layout>
  );
};

export default AdminFrontend;
