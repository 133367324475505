import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import companyLogo from "../../assets/3SC-logo.svg";
import Loader from "../../components/Loader";
import { EnvelopeIcon } from "../../components/icons/EnvelopeIcon";
import InputField from "../../globalComponent/InputField";
import { forgotPassword } from "../../utils/ApiHandler";
// import "./forgot-password.scss";
import { Box, InputBase, Stack, Typography } from "@mui/material";
import ThreeDot from "../../components/typing-animation/three.dot";
import "../Login/Login.scss";
import BackBtn from "../../components/button/back.btn";
import TypingAnimation from "../../components/typing-animation";
import RipperContainer from "../../components/ripple-container";
import images from "../../constant/images";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, SetFormData] = useState({
    email: "",
  });
  const { t } = useTranslation();

  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmailChange, seIsEmailChange] = useState(false);
  let emailChangeTimeout;

  const { email } = formData;

  // useEffect(() => {
  //   if (email && !validator.isEmail(email)) setEmailError(true);
  //   else setEmailError(false);
  // }, [email]);

  useEffect(() => {
    clearTimeout(emailChangeTimeout);
    if (isEmailChange) {
      emailChangeTimeout = setTimeout(() => {
        seIsEmailChange(false);
      }, 1000);
    }
    return () => {
      clearTimeout(emailChangeTimeout);
    };
  }, [email]);

  const onChange = (e) => {
    seIsEmailChange(true);
    SetFormData({
      ...formData,
      [e.target.name]: e.target.value?.toLowerCase(),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await forgotPassword(email);
    if (res?.status === 200) {
      toast.success("Password reset link sent to your mail ID !!!");
      navigate(`/`);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <Box className="login-container">
        <Box
          component="header"
          sx={{
            py: 2,
            px: 12,
            height: 64,
          }}
        >
          <Typography className="logo-heading">
            <TypingAnimation text="SCAi" />
          </Typography>
        </Box>
        <Box component="form" className="login-form" onSubmit={onSubmit}>
          <Stack className="email-con">
            <Typography variant="h1" className="heading">
              Recover your <br /> account?
            </Typography>

            <Typography className="paragraph">
              No worries, we'll send you reset link.
            </Typography>

            <Stack>
              <Box className="input-box">
                <InputBase
                  className="input-base"
                  placeholder="Enter e-mail address"
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Box className="indicator-box">
                  <ThreeDot isAnimation={isEmailChange} />
                </Box>
              </Box>
              <Box
                component="button"
                className="btn"
                disabled={emailError || !email || loading}
                type="submit"
              >
                Reset
              </Box>
              <Box
                sx={{
                  mt: "4rem",
                }}
              >
                <Link to={"/"}>
                  <BackBtn />
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
        <Box className="com-logo">
          <RipperContainer image={images.comLogo} />
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;

// <div className="forgot-password-container">
//     <div className="forgot-password">
//       <div className="logo-container">
//         <div className="logo-container-logo">
//           <img src={companyLogo} alt="logo" className="logo-img" />
//         </div>
//       </div>
//       <form className="forgot-password-form" onSubmit={onSubmit}>
//         <h1 className="forgot-password-heading">{"Forgot Password?"}</h1>
//         <span className="forgot-password-subheading">
//           {"Send a link on your mail ID"}
//         </span>
//         <InputField
//           className="forgot-password-input mb-0"
//           containerClassName="input-email-address"
//           id="outlined-basic"
//           label={"Email ID"}
//           variant="outlined"
//           name="email"
//           value={email}
//           icon={[<EnvelopeIcon />]}
//           helperText={
//             emailError ? (
//               <div style={{ color: "red" }} key="mail">
//                 {t("emailAddressValidation")}
//               </div>
//             ) : null
//           }
//           onChange={(e) => onChange(e)}
//         />

//         <button
//           className="change-password-button primary-button"
//           disabled={emailError || !email || loading}
//           type="submit"
//         >
//           {"Change Password"}
//         </button>

//         <div className="forgot-password-back">
//           <Link to={"/"} className="remove-underline">
//             <KeyboardBackspaceIcon />
//             <span>{"Back to Login"}</span>
//           </Link>
//         </div>
//       </form>
//     </div>
//   </div>
