import React from "react";
export const RawmaterialIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="35" y="19" width="2" height="2" />
      <rect x="41" y="19" width="2" height="2" />
      <rect x="47" y="19" width="2" height="2" />
      <rect x="32" y="22" width="2" height="2" />
      <rect x="38" y="22" width="2" height="2" />
      <rect x="44" y="22" width="2" height="2" />
      <rect x="50" y="22" width="2" height="2" />
      <path d="M27.1,21.3l0.7-6.3h0.6c0.3,0,0.5-0.2,0.5-0.5v-2c0-0.3-0.2-0.5-0.5-0.5h-0.7c0.5-1.2,0-2.7-1.3-3.3
		c-0.3-0.1-0.7-0.2-1-0.2c-0.2-1.1-1.2-2-2.4-2c-0.2,0-0.4,0.1-0.7,0.1c-0.5-1.3-1.9-1.9-3.2-1.4c-0.6,0.2-1.1,0.7-1.4,1.4
		c-0.2,0-0.4-0.1-0.6-0.1c-1.2,0-2.2,0.8-2.4,2C13.1,8.5,12,9.6,12,11c0,0.3,0,0.7,0.2,1h-0.7c-0.3,0-0.5,0.2-0.5,0.5v2
		c0,0.3,0.2,0.5,0.5,0.5h0.6l0.7,6H6.4L6,13.7l0.8-0.8C6.9,12.8,7,12.7,7,12.6v-2c0-0.1-0.1-0.2-0.1-0.3L5.5,8.8v-3l1.2-1.2h2.7v1
		H9.1c-0.1,0-0.2,0-0.3,0.1L7.6,6.8C7.5,6.9,7.4,7,7.4,7.2v1.6c0,0.1,0.1,0.2,0.1,0.3l1.2,1.2l0.7-0.7l-1-1V7.4l0.9-0.9h1.2l0.9,0.9
		v1.2l-1,1l0.7,0.7l1.2-1.2c0.1-0.1,0.1-0.2,0.1-0.3V7.2c0-0.1-0.1-0.2-0.1-0.3l-1.2-1.1c-0.1-0.1-0.2-0.1-0.4-0.1h-0.3v-1H13
		c0.3,0,0.5-0.2,0.5-0.5V2c0-0.3-0.2-0.5-0.5-0.5H6.7L5.4,0.2C5.3,0.1,5.1,0,5,0H2.9c-0.1,0-0.2,0-0.3,0.1L1.1,1.6C1,1.7,1,1.9,1,2
		v2c0,0.1,0,0.2,0.1,0.3l1.3,1.4v3l-1.3,1.3C1,10.2,1,10.4,1,10.5v2c0,0.1,0,0.2,0.1,0.3l0.8,0.8l-0.4,8.4c-1.8,1.6-2,4.4-0.4,6.3
		c0.8,1,2.1,1.5,3.4,1.5h21c2.5,0,4.5-2,4.5-4.5C30,23.6,28.8,22,27.1,21.3z M13,11c0-0.8,0.6-1.5,1.5-1.5c0.1,0,0.2,0,0.3,0
		c0.1,0.1,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.1,0-0.1c0-0.8,0.6-1.5,1.5-1.5c0.2,0,0.5,0.1,0.7,0.2
		c0.1,0.1,0.3,0.1,0.5,0c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.8,0.9-1.4,1.7-1.2c0.6,0.1,1.2,0.6,1.2,1.2c0,0.1,0.1,0.3,0.2,0.4
		c0.1,0.1,0.3,0.1,0.5,0c0.2-0.1,0.5-0.2,0.7-0.2c0.8,0,1.5,0.6,1.5,1.5c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0.2,0.4
		c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0c0.8,0,1.5,0.6,1.5,1.5c0,0.3-0.1,0.7-0.4,1H13.4C13.1,11.8,13,11.4,13,11z M12,13h16v1
		H12V13z M13.1,15h13.7l-0.7,6c-0.2,0-0.4,0-0.6,0H13.8L13.1,15z M3.5,6h1v2.5h-1V6z M12.5,3.5H7v-1h5.5V3.5z M2,2.2L3.1,1h1.6
		L6,2.2v1.6L4.8,5H3.1L2,3.9V2.2z M2,10.7l1.1-1.1h1.6l1.1,1.1v1.6l-1.1,1.2H3.1L2,12.4V10.7z M2.9,14.5L2.9,14.5H5H5L5.3,21H4.5
		c-0.6,0-1.3,0.1-1.9,0.4L2.9,14.5z M25.5,29h-21C2.5,29,1,27.4,1,25.5S2.5,22,4.5,22h21c1.9,0,3.5,1.5,3.5,3.5S27.4,29,25.5,29z"/>
	<path d="M25.5,23H22v1h1.5c-0.7,0.9-0.7,2.1,0,3H17c0.7-0.9,0.7-2.1,0-3h4v-1H4.5C3.1,23,2,24.1,2,25.5
		C2,26.9,3.1,28,4.5,28H8v-1H6.5c0.7-0.9,0.7-2.1,0-3H13c-0.7,0.9-0.7,2.1,0,3H9v1h16.5c1.4,0,2.5-1.1,2.5-2.5
		C28,24.1,26.9,23,25.5,23z M6,25.5C6,26.3,5.3,27,4.5,27S3,26.3,3,25.5C3,24.6,3.6,24,4.5,24S6,24.6,6,25.5z M13.5,25.5
		c0-0.8,0.6-1.5,1.5-1.5s1.5,0.6,1.5,1.5c0,0.8-0.6,1.5-1.5,1.5S13.5,26.3,13.5,25.5z M25.5,27c-0.8,0-1.5-0.6-1.5-1.5
		c0-0.8,0.6-1.5,1.5-1.5c0.8,0,1.5,0.6,1.5,1.5C27,26.3,26.3,27,25.5,27z"/>
	<path d="M5.5,3c0-0.8-0.6-1.5-1.5-1.5S2.5,2.2,2.5,3S3.1,4.5,4,4.5S5.5,3.9,5.5,3z M3.5,3c0-0.3,0.2-0.5,0.5-0.5
		S4.5,2.7,4.5,3S4.3,3.5,4,3.5S3.5,3.3,3.5,3z"/>
	<path d="M5.5,11.5C5.5,10.7,4.8,10,4,10s-1.5,0.6-1.5,1.5C2.5,12.4,3.1,13,4,13S5.5,12.4,5.5,11.5z M3.5,11.5
		C3.5,11.2,3.7,11,4,11s0.5,0.2,0.5,0.5C4.5,11.8,4.3,12,4,12S3.5,11.8,3.5,11.5z"/>
      <rect x="10" y="52" width="2" height="2" />
      <rect x="31" y="52" width="2" height="2" />
      <rect x="52" y="52" width="2" height="2" />
    </svg>
  );
};
