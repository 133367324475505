import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layouts/Layout";
import {
  CARBONX_ROUTES,
  DPAI_ROUTES,
  DT_ROUTES,
  EDM_ROUTES,
  INTERNAL_DASHBOARD_ROUTES,
  PRODUCT_ROUTES,
  RM_ROUTES,
  SNOP_ROUTES,
  SNP_ROUTES,
} from "../constant/constant";
import ErrorPage from "../globalComponent/ErrorPage/Error";
import AdminFrontend from "../pages/AdminFrontend/AdminFrontend";
import CarbonFrontend from "../pages/CarbonFrontend/CarbonFrontend";
import DTFrontend from "../pages/DTFrontend";
import DpaiFrontend from "../pages/DpaiFrontend/DpaiFrontend";
import EdmFrontend from "../pages/EdmFrontend/EdmFrontend";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import MyProfile from "../pages/MyProfile";
import OTP from "../pages/OTP";
import ProductFrontend from "../pages/ProductFrontend";
import RMFrontend from "../pages/RMFrontend";
import SetNewPassword from "../pages/SetNewPassword/SetNewPassword";
import SnpFrontend from "../pages/SnpFrontend/SnpFrontend";
import SnopFrontend from "../pages/snopFrontend";
import EndPoint from "../utils/EndPoints";
import PrivateRoute from "./PrivateRoute";

const Routing = () => {
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Login />} />
        <Route path="/scai" element={<Login />} />
        <Route path="/login/callback" element={<Login />} />
        <Route path="/scai/forgot-password" element={<ForgotPassword />} />
        <Route path="/scai/set-new-password" element={<SetNewPassword />} />
        <Route path="/scai/login-mfa" element={<OTP />} />
        <Route
          path={EndPoint.MY_PROFILE}
          element={
            <Layout>
              <MyProfile />
            </Layout>
          }
        />

        {/* Public routes */}

        {/* Internal Dashboard routes */}
        {INTERNAL_DASHBOARD_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <AdminFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* Internal Dashboard routes */}

        {/* EDM routes */}
        {EDM_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <EdmFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* EDM routes */}

        {/* SNOP routes */}
        {SNOP_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <SnopFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* SNOP routes */}

        {/* DPAI routes */}
        {DPAI_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <DpaiFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* DPAI routes */}

        {/* SNP routes */}
        {SNP_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <SnpFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* SNP routes */}

        {/* Carbon X Routing */}
        {CARBONX_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <CarbonFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* Carbon X Routing */}

        {/* DTRM Routing */}
        {RM_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <RMFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {DT_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <DTFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}
        {/* DTRM Routing */}

        {/* Product Routing */}
        {PRODUCT_ROUTES.map((routeElement) => {
          return (
            <Route
              path={routeElement.path}
              element={
                <PrivateRoute path={routeElement.childPath}>
                  <ProductFrontend />{" "}
                </PrivateRoute>
              }
            />
          );
        })}

        {/* Wildcard route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Routing;
