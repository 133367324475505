// eslint-disable-next-line import/no-anonymous-default-export
/* 
List of All the literals and constants used in Project
*/
const constKeys = {
  api: '',
  API_METHOD_POST: 'POST',
  API_METHOD_GET: 'GET',
  API_METHOD_PUT: 'PUT',
  API_METHOD_PATCH: 'PATCH',
  API_METHOD_DELETE: 'DELETE',
  API_METHOD_HEAD: 'HEAD',
  FORM_VALIDATION_EXAMPLE: 'Form Inputs Validation Example',
  ERROR_BOUNDARY_EXAMPLE: 'Error Boundary Example',
  API_RESPONSE_ERROR: 'Something went wrong with API',
  GLOBAL_ERROR: 'Something went wrong',
};

export const supportedLanguage = ["en-US"];

export const adminType = {
  "3SCAdmin": "3SCAdmin",
  "Client Admin": "Client Admin",
}

export const createThemeSelect = (theme) => {
  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary25: "#F2F2F2",
      primary: "#E94F1C",
    },
  };
};

export const userEditInitialValues = {
  organization: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile_country_code: "",
  mobile: "",
  team: "",
  business_unit: "",
  address_line1: "",
  address_line2: "",
  country: null,
  state: null,
  city: null,
  pincode: "",
  roles: "",
}

export const phoneInputValidateMsg = "This field is mandatory"

export const nameValidation = [
  "required",
  "alpha_num_dash_space",
  { max: "64" },
];

export default constKeys;

export const INTERNAL_DASHBOARD_ROUTES = [
  { path: "/admin/client", childPath: "/admin/client" },
  { path: "/admin/configuration", childPath: "/admin/configuration" },
  { path: "/admin/setup/create", childPath: "/admin/setup/create" },
  { path: "/admin/setup/edit", childPath: "/admin/setup/edit" },
  { path: "/admin/setup", childPath: "/admin/setup" },
  { path: "/admin/setup/configuration", childPath: "/admin/setup/configuration" },
  { path: "/admin/setup/team", childPath: "/admin/setup/team" },
  { path: "/admin/setup/team/create", childPath: "/admin/setup/edit" },
  { path: "/admin/setup/team/edit", childPath: "/admin/setup/team/edit" },
  { path: "/admin/setup/team/user", childPath: "/admin/setup/team/user" },
  { path: "/admin/user", childPath: "/admin/user" },
  { path: "/admin/user/create", childPath: "/admin/user/create" },
  { path: "/admin/user/edit", childPath: "/admin/user/edit" },
  { path: "/admin/client/edit", childPath: "/admin/client/edit" },
  { path: "/admin/role", childPath: "/admin/role" },
  { path: "/admin/role/edit", childPath: "/admin/role/edit" },
];

export const EDM_ROUTES = [
  { path: "/edm/product", childPath: "/edm/product" },
  { path: "/edm/personnel", childPath: "/edm/personnel" },
  { path: "/edm/mapping", childPath: "/edm/mapping" },
  { path: "/edm/network", childPath: "/edm/network" },
  { path: "/edm/location", childPath: "/edm/location" },
  { path: "/edm/currency", childPath: "/edm/currency" },
  { path: "/edm/actual-sales-history", childPath: "/edm/actual-sales-history" },
  { path: "/edm/rm-network", childPath: "/edm/rm-network" },
  { path: "/edm/feature", childPath: "/edm/feature" },
  { path: "/edm/forecast-summary", childPath: "/edm/forecast-summary" },
  { path: "/edm/transactionlog", childPath: "/edm/transactionlog" },
  { path: "/edm/goods-in-transit", childPath: "/edm/goods-in-transit" },
  { path: "/edm/goods-in-hand", childPath: "/edm/goods-in-hand" },
  { path: "/edm/goods-in-pbns", childPath: "/edm/goods-in-pbns" },
  { path: "/edm/production-work-order", childPath: "/edm/production-work-order" },
  { path: "/edm/bill-of-materials", childPath: "/edm/bill-of-materials" },
  { path: "/edm/stock-transfer-order", childPath: "/edm/stock-transfer-order" },
  { path: "/edm/raw-material", childPath: "/edm/raw-material" },
  { path: "/edm/purchase-order", childPath: "/edm/purchase-order" },
  { path: "/edm/rm-node-mapping", childPath: "/edm/rm-node-mapping" },
  { path: "/edm/rmpo", childPath: "/edm/rmpo" },
  { path: "/edm/shipment", childPath: "/edm/shipment" },
  { path: "/edm/promo-transaction", childPath: "/edm/promo-transaction" },
  { path: "/edm/promo-master", childPath: "/edm/promo-master" },
  { path: "/edm/budget", childPath: "/edm/budget" },
  { path: "/edm/marketing-events", childPath: "/edm/marketing-events" },
  { path: "/edm/promotion-type", childPath: "/edm/promotion-type" },
  { path: "/edm/splitmaster", childPath: "/edm/splitmaster" },
  { path: "/edm/route-master", childPath: "/edm/route-master" },
  { path: "/edm/snp-personnel", childPath: "/edm/snp-personnel" },
  { path: "/edm/sales-orders", childPath: "/edm/sales-orders" },
]

export const SNOP_ROUTES = [
  { path: "/snop", childPath: "/snop" },
]

export const DPAI_ROUTES = [
  { path: "/dp/forecast", childPath: "/dp/forecast" },
  { path: "/dp/analytics", childPath: "/dp/analytics" },
  { path: "/dp/collaboration", childPath: "/dp/collaboration" },
  { path: "/dp/simulate/scenario", childPath: "/dp/simulate/scenario" },
  { path: "/dp/simulate/scenario/edit", childPath: "/dp/simulate/scenario/edit" },
  { path: "/dp/simulate/scenario/create", childPath: "/dp/simulate/scenario/create" },
  { path: "/dp/simulate/scenario/case", childPath: "/dp/simulate/scenario/case" },
  { path: "/dp/simulate/scenario/case/create", childPath: "/dp/simulate/scenario/case/create" },
  { path: "/dp/simulate/scenario/case/edit", childPath: "/dp/simulate/scenario/case/edit" },
  { path: "/dp/simulate/scenario/case/compare", childPath: "/dp/simulate/scenario/case/compare" },
  { path: "/dp/reports", childPath: "/dp/reports" },
  { path: "/dp/reports/details", childPath: "/dp/reports/details" },
]

export const SNP_ROUTES = [
  { path: "/snp/reports", childPath: "/snp/reports" },
  { path: "/snp/reports/:id", childPath: "/snp/reports/:id" },
  { path: "/snp/inventory/management", childPath: "/snp/inventory/management" },
  { path: "/snp/inventory/management/inventory-stats", childPath: "/snp/inventory/management/inventory-stats" },
  { path: "/snp/inventory/management/preprocesses", childPath: "/snp/inventory/management/preprocesses" },
  { path: "/snp/inventory/management/preprocesses/process/:type", childPath: "/snp/inventory/management/preprocesses/process/:type" },
  { path: "/snp/inventory/management/job-scheduler", childPath: "/snp/inventory/management/job-scheduler" },
  { path: "/snp/inventory/management/job-scheduler-create", childPath: "/snp/inventory/management/job-scheduler-create" },
  { path: "/snp/inventory/management/job-scheduler-history/:id", childPath: "/snp/inventory/management/job-scheduler-history/:id" },
  { path: "/snp/inventory/management/job-scheduler-edit/:id", childPath: "/snp/inventory/management/job-scheduler-edit/:id" },
  { path: "/snp/inventory/management/config", childPath: "/snp/inventory/management/config" },
  { path: "/snp/inventory/management/config-create", childPath: "/snp/inventory/management/config-create" },
  { path: "/snp/inventory/management/config-edit/:id", childPath: "/snp/inventory/management/config-edit/:id" },
  { path: "/snp/inventory/management/network", childPath: "/snp/inventory/management/network" },
  { path: "/snp/inventory/replenishment-plan", childPath: "/snp/inventory/replenishment-plan" },
  { path: "/snp/inventory/replenishment-plan/org-plan/:planId", childPath: "/snp/inventory/replenishment-plan/org-plan/:planId" },
  { path: "/snp/inventory/replenishment-plan/group-plan/:planId", childPath: "/snp/inventory/replenishment-plan/group-plan/:planId" },
  { path: "/snp/inventory/replenishment-plan/preprocesses", childPath: "/snp/inventory/replenishment-plan/preprocesses" },
  { path: "/snp/inventory/replenishment-plan/preprocesses/process/:type", childPath: "/snp/inventory/replenishment-plan/preprocesses/process/:type" },
  { path: "/snp/inventory/replenishment-plan/job-scheduler", childPath: "/snp/inventory/replenishment-plan/job-scheduler" },
  { path: "/snp/inventory/replenishment-plan/job-scheduler-create", childPath: "/snp/inventory/replenishment-plan/job-scheduler-create" },
  { path: "/snp/inventory/replenishment-plan/job-scheduler-history/:id", childPath: "/snp/inventory/replenishment-plan/job-scheduler-history/:id" },
  { path: "/snp/inventory/replenishment-plan/job-scheduler-edit/:id", childPath: "/snp/inventory/replenishment-plan/job-scheduler-edit/:id" },
  { path: "/snp/inventory/replenishment-plan/config", childPath: "/snp/inventory/replenishment-plan/config" },
  { path: "/snp/inventory/replenishment-plan/config-create", childPath: "/snp/inventory/replenishment-plan/config-create" },
  { path: "/snp/inventory/replenishment-plan/config-edit/:id", childPath: "/snp/inventory/replenishment-plan/config-edit/:id" },
  { path: "/snp/inventory/replenishment-plan/network", childPath: "/snp/inventory/replenishment-plan/network" },
  { path: "/snp/production/requirement", childPath: "/snp/production/requirement" },
  { path: "/snp/production/requirement/preprocesses", childPath: "/snp/production/requirement/preprocesses" },
  { path: "/snp/production/requirement/job-scheduler", childPath: "/snp/production/requirement/job-scheduler" },
  { path: "/snp/production/requirement/job-scheduler-create", childPath: "/snp/production/requirement/job-scheduler-create" },
  { path: "/snp/production/requirement/job-scheduler-history/:id", childPath: "/snp/production/requirement/job-scheduler-history/:id" },
  { path: "/snp/production/requirement/job-scheduler-edit/:id", childPath: "/snp/production/requirement/job-scheduler-edit/:id" },
  { path: "/snp/production/requirement/config", childPath: "/snp/production/requirement/config" },
  { path: "/snp/production/requirement/config-create", childPath: "/snp/production/requirement/config-create" },
  { path: "/snp/production/requirement/config-edit", childPath: "/snp/production/requirement/config-edit/:id" },
  { path: "/snp/production/requirement/network", childPath: "/snp/production/requirement/network" },
  { path: "/snp/production/production-plan", childPath: "/snp/production/production-plan" },
  { path: "/snp/production/production-plan/app-group-level-view/:id", childPath: "/snp/production/production-plan/app-group-level-view/:id" },
  { path: "/snp/production/production-plan/app-org-level-view/:id", childPath: "/snp/production/production-plan/app-org-level-view/:id" },
  { path: "/snp/production/production-plan/app-group-level-view/:id/add-pwo", childPath: "/snp/production/production-plan/app-group-level-view/:id/add-pwo" },
  { path: "/snp/raw-material/requirement", childPath: "/snp/raw-material/requirement" },
  { path: "/snp/raw-material/requirement/preprocesses", childPath: "/snp/raw-material/requirement/preprocesses" },
  { path: "/snp/raw-material/requirement/job-scheduler", childPath: "/snp/raw-material/requirement/job-scheduler" },
  { path: "/snp/raw-material/requirement/job-scheduler-create", childPath: "/snp/raw-material/requirement/job-scheduler-create" },
  { path: "/snp/raw-material/requirement/job-scheduler-edit/:id", childPath: "/snp/raw-material/requirement/job-scheduler-edit/:id" },
  { path: "/snp/raw-material/requirement/config", childPath: "/snp/raw-material/requirement/config" },
  { path: "/snp/raw-material/requirement/config-create", childPath: "/snp/raw-material/requirement/config-create" },
  { path: "/snp/raw-material/requirement/config-edit/:id", childPath: "/snp/raw-material/requirement/config-edit/:id" },
  { path: "/snp/raw-material/requirement/network", childPath: "/snp/raw-material/requirement/network" },
  { path: "/snp/raw-material/plan", childPath: "/snp/raw-material/plan" },
  { path: "/snp/inventory/management/demand-overview", childPath: "/snp/inventory/management/demand-overview" },
  { path: "/snp/production/production-plan/preprocesses", childPath: "/snp/production/production-plan/preprocesses" },
  { path: "/snp/production/production-plan/preprocesses/process/:type", childPath: "/snp/production/production-plan/preprocesses/process/:type" },
  { path: "/snp/production/production-plan/job-scheduler", childPath: "/snp/production/production-plan/job-scheduler" },
  { path: "/snp/production/production-plan/job-scheduler-create", childPath: "/snp/production/production-plan/job-scheduler-create" },
  { path: "/snp/production/production-plan/job-scheduler-history/:id", childPath: "/snp/production/production-plan/job-scheduler-history/:id" },
  { path: "/snp/production/production-plan/job-scheduler-edit/:id", childPath: "/snp/production/production-plan/job-scheduler-edit/:id" },
  { path: "/snp/production/production-plan/config", childPath: "/snp/production/production-plan/config" },
  { path: "/snp/production/production-plan/config-create", childPath: "/snp/production/production-plan/config-create" },
  { path: "/snp/production/production-plan/config-edit/:id", childPath: "/snp/production/production-plan/config-edit/:id" },
  { path: "/snp/production/production-plan/network", childPath: "/snp/production/production-plan/network" },
  { path: "/snp/raw-material/plan/preprocesses", childPath: "/snp/raw-material/plan/preprocesses" },
  { path: "/snp/raw-material/plan/preprocesses/process/:type", childPath: "/snp/raw-material/plan/preprocesses/process/:type" },
  { path: "/snp/raw-material/plan/job-scheduler", childPath: "/snp/raw-material/plan/job-scheduler" },
  { path: "/snp/raw-material/plan/job-scheduler-create", childPath: "/snp/raw-material/plan/job-scheduler-create" },
  { path: "/snp/raw-material/plan/job-scheduler-edit/:id", childPath: "/snp/raw-material/plan/job-scheduler-edit/:id" },
  { path: "/snp/raw-material/plan/config", childPath: "/snp/raw-material/plan/config" },
  { path: "/snp/raw-material/plan/config-create", childPath: "/snp/raw-material/plan/config-create" },
  { path: "/snp/raw-material/plan/config-edit/:id", childPath: "/snp/raw-material/plan/config-edit/:id" },
  { path: "/snp/raw-material/plan/network", childPath: "/snp/raw-material/plan/network" },
  { path: "/snp/inventory/replenishment-plan/group-plan/:planId/add-sto", childPath: "/snp/inventory/replenishment-plan/group-plan/:planId/add-sto" },
  { path: "/snp/production/requirement/preprocesses/process/:type", childPath: "/snp/production/requirement/preprocesses/process/:type" },
  { path: "/snp/raw-material/requirement/preprocesses/process/:type", childPath: "/snp/raw-material/requirement/preprocesses/process/:type" },
  { path: "/snp/raw-material/plan/org-plan/:planId", childPath: "/snp/raw-material/plan/org-plan/:planId" },
  { path: "/snp/raw-material/plan/group-plan/:planId", childPath: "/snp/raw-material/plan/group-plan/:planId" },
  { path: "/snp/raw-material/plan/group-plan/:planId/add-rmpr", childPath: "/snp/raw-material/plan/group-plan/:planId/add-rmpr" },
  { path: "/snp/orders-management", childPath: "/snp/orders-management" },
]

export const CARBONX_ROUTES = [
  { path: "/cx/carbon-analytics/executive-summary", childPath: "/cx/carbon-analytics/executive-summary" },
  { path: "/cx/carbon-analytics/detailed-summary", childPath: "/cx/carbon-analytics/detailed-summary" },
  { path: "/cx/carbon-analytics/equivalence", childPath: "/cx/carbon-analytics/equivalence" },
  { path: "/cx/carbon-analytics/computation", childPath: "/cx/carbon-analytics/computation" },
  { path: "/cx/carbon-analytics/computation/scope", childPath: "/cx/carbon-analytics/computation/scope" },
  { path: "/cx/carbon-analytics/singleview", childPath: "/cx/carbon-analytics/singleview" },
  { path: "/cx/entity-management/transactional/scope-1", childPath: "/cx/entity-management/transactional/scope-1" },
  { path: "/cx/entity-management/transactional/scope-1-1", childPath: "/cx/entity-management/transactional/scope-1-1" },
  { path: "/cx/entity-management/transactional/scope-1-2", childPath: "/cx/entity-management/transactional/scope-1-2" },
  { path: "/cx/entity-management/transactional/scope-1-3", childPath: "/cx/entity-management/transactional/scope-1-3" },
  { path: "/cx/entity-management/transactional/scope-2", childPath: "/cx/entity-management/transactional/scope-2" },
  { path: "/cx/entity-management/transactional/scope-3-1", childPath: "/cx/entity-management/transactional/scope-3-1" },
  { path: "/cx/entity-management/transactional/scope-3-2", childPath: "/cx/entity-management/transactional/scope-3-2" },
  { path: "/cx/entity-management/transactional/scope-3-3", childPath: "/cx/entity-management/transactional/scope-3-3" },
  { path: "/cx/entity-management/transactional/scope-3-4", childPath: "/cx/entity-management/transactional/scope-3-4" },
  { path: "/cx/entity-management/transactional/scope-3-5", childPath: "/cx/entity-management/transactional/scope-3-5" },
  { path: "/cx/entity-management/transactional/scope-3-6", childPath: "/cx/entity-management/transactional/scope-3-6" },
  { path: "/cx/entity-management/transactional/scope-3-7", childPath: "/cx/entity-management/transactional/scope-3-7" },
  { path: "/cx/entity-management/transactional/scope-3-8", childPath: "/cx/entity-management/transactional/scope-3-8" },
  { path: "/cx/entity-management/transactional/scope-3-9", childPath: "/cx/entity-management/transactional/scope-3-9" },
  { path: "/cx/entity-management/transactional/scope-3-10", childPath: "/cx/entity-management/transactional/scope-3-10" },
  { path: "/cx/entity-management/transactional/scope-3-11", childPath: "/cx/entity-management/transactional/scope-3-11" },
  { path: "/cx/entity-management/transactional/scope-3-12", childPath: "/cx/entity-management/transactional/scope-3-12" },
  { path: "/cx/entity-management/transactional/scope-3-13", childPath: "/cx/entity-management/transactional/scope-3-13" },
  { path: "/cx/entity-management/transactional/scope-3-14", childPath: "/cx/entity-management/transactional/scope-3-14" },
  { path: "/cx/entity-management/transactional/scope-3-15", childPath: "/cx/entity-management/transactional/scope-3-15" },
  { path: "/cx/data-mapping-layer/upload", childPath: "/cx/data-mapping-layer/upload" },
  { path: "/cx/data-mapping-layer/rule-selection", childPath: "/cx/data-mapping-layer/rule-selection" },
  { path: "/cx/entity-management/master/vehicle-entity", childPath: "/cx/entity-management/master/vehicle-entity" },
]

export const RM_ROUTES = [
  { path: "/riskai", childPath: "/riskai" },
  { path: "/riskai/riskdetails", childPath: "/riskai/riskdetails" },
  { path: "/riskai/reports", childPath: "/riskai/reports" },
  { path: "/riskai/resilienceoverall", childPath: "/riskai/resilienceoverall" },
  { path: "/riskai/supplier", childPath: "/riskai/supplier" },
  { path: "/riskai/manufacturing", childPath: "/riskai/manufacturing" },
  { path: "/riskai/transportation", childPath: "/riskai/transportation" },
  { path: "/riskai/warehouse", childPath: "/riskai/warehouse" },
  { path: "/riskai/customer", childPath: "/riskai/customer" },
  { path: "/riskai/fginventory", childPath: "/riskai/fginventory" },
  { path: "/riskai/rminventory", childPath: "/riskai/rminventory" },
  { path: "/riskai/demand", childPath: "/riskai/demand" },
  { path: "/riskai/resilienceoverall/collab", childPath: "/riskai/resilienceoverall/collab" },
  { path: "/riskai/supplierdetail", childPath: "/riskai/supplierdetail" },
  { path: "/riskai/manufacturingdetail", childPath: "/riskai/manufacturingdetail" },
  { path: "/riskai/transportationdetail", childPath: "/riskai/transportationdetail" },
  { path: "/riskai/warehousedetail", childPath: "/riskai/warehousedetail" },
  { path: "/riskai/customerdetail", childPath: "/riskai/customerdetail" },
  { path: "/riskai/fginventorydetail", childPath: "/riskai/fginventorydetail" },
  { path: "/riskai/rminventorydetail", childPath: "/riskai/rminventorydetail" },
  { path: "/riskai/demanddetail", childPath: "/riskai/demanddetail" },
  { path: "/riskai/gauri", childPath: "/riskai/gauri" },
  { path: "/riskai/gauri/chatresponse", childPath: "/riskai/gauri/chatresponse" },
  { path: "/riskai/worldengine", childPath: "/riskai/worldengine" },
  { path: "/riskai/simulationresults", childPath: "/riskai/simulationresults" },
]

export const DT_ROUTES = [
  { path: "/dt", childPath: "/dt" },
  { path: "/dt/nodestats", childPath: "/dt/nodestats" },
  { path: "/dt/shipmentstats", childPath: "/dt/shipmentstats" },
]

export const PRODUCT_ROUTES = [
  { path: "/pp/forecast", childPath: "/pp/forecast" }
]

export const colorPalette = {
  grey: "#72757a",
  green: "#1BD365",
}