import React from "react";
export const FeatureIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1868 4.44575C18.1868 3.9502 17.9067 3.49761 17.464 3.27621L16.9746 3.03125V5.86023L17.464 5.61526C17.9069 5.39388 18.1868 4.94108 18.1868 4.44573V4.44575Z"
        fill={fillColor}
      />
      <path
        d="M10.1033 8.08292H9.69922V8.89128H10.1033C10.7717 8.89128 11.3158 8.34742 11.3158 7.67884V7.35596H10.5074L10.5076 7.67884C10.5076 7.90195 10.326 8.08293 10.1033 8.08293L10.1033 8.08292Z"
        fill={fillColor}
      />
      <path
        d="M11.3162 12.1416L10.5078 12.95L10.508 21.4197C10.508 22.0881 11.0519 22.6321 11.7205 22.6321H15.3576V21.8238H11.7205C11.4977 21.8238 11.3164 21.6428 11.3164 21.4197V18.5907H15.3578V17.7823L11.3162 17.7825V14.5495H15.3576V13.7411L11.3162 13.7413L11.3162 12.1416Z"
        fill={fillColor}
      />
      <path
        d="M8.38865 13.2767C8.19666 13.3556 8.08278 13.5257 8.08278 13.716V14.5493H7.27441V18.5907H8.89094L8.89114 13.3699C8.75483 13.2438 8.56013 13.2065 8.38866 13.2769L8.38865 13.2767Z"
        fill={fillColor}
      />
      <path
        d="M4.84961 14.5488H6.46614V18.5903H4.84961V14.5488Z"
        fill={fillColor}
      />
      <path
        d="M3.86554 21.0155H7.45032L8.77299 19.3989H2.54297L3.86554 21.0155Z"
        fill={fillColor}
      />
      <path
        d="M4.04135 14.5492V13.783C4.04135 13.5518 3.90311 13.3457 3.68927 13.2577C3.50772 13.1833 3.30413 13.2068 3.14442 13.3203L2.4248 13.8331V18.5906H4.04133L4.04135 14.5492Z"
        fill={fillColor}
      />
      <path
        d="M5.65857 23.2057L6.78964 21.8232H4.52734L5.65857 23.2057Z"
        fill={fillColor}
      />
      <path d="M8.08301 7.2749H8.89137V10.508H8.08301V7.2749Z" fill={fillColor} />
      <path d="M15.3574 0H16.1662V8.89091H15.3574V0Z" fill={fillColor} />
      <path
        d="M14.5492 7.57465V1.31592C13.631 2.03207 12.5107 2.42452 11.3279 2.42452H8.08301V6.46595H11.3279C12.5107 6.46575 13.631 6.85824 14.5492 7.5744V7.57465Z"
        fill={fillColor}
      />
      <path
        d="M11.1917 11.0931C10.8243 10.7257 10.7149 10.1778 10.9125 9.69713C10.9484 9.60936 10.9954 9.52989 11.0466 9.4543C10.7634 9.60511 10.4457 9.69888 10.1033 9.69888H9.69922V10.5072C9.69922 10.9531 9.3367 11.3156 8.89085 11.3156H8.08249C8.01173 11.3156 7.94425 11.3034 7.8789 11.2866C7.33271 11.5619 6.71595 11.7195 6.06206 11.7195C3.8299 11.7195 2.02064 9.91018 2.02064 7.67827C2.02064 5.84114 3.24798 4.29287 4.92638 3.80177C5.18913 2.6804 6.11987 1.81422 7.2757 1.64811C7.2757 1.64405 7.27454 1.63999 7.27454 1.63555V1.61621H4.84964V1.64135C4.84964 2.16145 4.53894 2.62507 4.0583 2.82192C3.56122 3.02686 3.01637 2.91801 2.65075 2.5524L2.63296 2.53403L0.918179 4.24881L0.932293 4.26292C1.29964 4.63028 1.40908 5.1782 1.21148 5.65885C1.00866 6.15381 0.542297 6.46588 0.0197209 6.46588H0V8.89077H0.0251345C0.544839 8.89077 1.00787 9.20147 1.20571 9.68171C1.41065 10.1799 1.30102 10.7248 0.936187 11.0896L0.908539 11.1175L2.47904 12.8015L2.67586 12.6613C3.06293 12.3852 3.55694 12.3287 3.99661 12.5093C4.50181 12.7173 4.83284 13.1972 4.84887 13.7403H7.27456V13.7152C7.27456 13.1955 7.58526 12.7319 8.0659 12.5343C8.56258 12.3307 9.1105 12.4409 9.47751 12.8078L9.49163 12.822L11.2064 11.1072L11.1917 11.0931Z"
        fill={fillColor}
      />
      <path
        d="M7.27473 6.42548V2.46582C6.35367 2.65375 5.6582 3.47004 5.6582 4.44565C5.6582 5.42126 6.35367 6.23754 7.27473 6.42548Z"
        fill={fillColor}
      />
      <path
        d="M16.165 10.5078H16.9734V11.3162H16.165V10.5078Z"
        fill={fillColor}
      />
      <path
        d="M17.7822 10.5078H18.5906V11.3162H17.7822V10.5078Z"
        fill={fillColor}
      />
      <path
        d="M19.3984 10.5078H20.2068V11.3162H19.3984V10.5078Z"
        fill={fillColor}
      />
      <path
        d="M19.3984 4.0415H21.8233V4.84987H19.3984V4.0415Z"
        fill={fillColor}
      />
      <path
        d="M18.7363 2.92236L21.1616 0.902344L21.679 1.52337L19.2537 3.54338L18.7363 2.92236Z"
        fill={fillColor}
      />
      <path
        d="M18.7363 5.96946L19.2537 5.34863L21.6784 7.36925L21.161 7.99007L18.7363 5.96946Z"
        fill={fillColor}
      />
      <path
        d="M16.165 21.8232H23.4399V23.4398H16.165V21.8232Z"
        fill={fillColor}
      />
      <path
        d="M16.165 17.7822H23.4399V19.3988H16.165V17.7822Z"
        fill={fillColor}
      />
      <path
        d="M16.165 13.7407H23.4399V15.3573H16.165V13.7407Z"
        fill={fillColor}
      />
    </svg>
  );
};
