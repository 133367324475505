import { createBrowserHistory } from "history";
import React from "react";
import MicroFrontend from "../../MicroFrontend";
import Layout from "../../components/Layouts/Layout";
import "../style.scss";
const defaultHistory = createBrowserHistory();

const ProductFrontend = () => {
    const { REACT_APP_PRODUCT_HOST: productHost } = process.env;

    function ProductModule({ history }) {
        return <MicroFrontend history={history} host={productHost} name="pp" />;
    }

    return (
        <Layout>
            <div id="product">
                <div>
                    <ProductModule history={defaultHistory} />
                </div>
            </div>
        </Layout>
    )
}
export default ProductFrontend;
