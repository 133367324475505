import { Notification } from "@3sc/common-component";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Cookies from 'js-cookie';
import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { AiOutlineExpand, AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import HamburgerMenu from "../../../assets/hamburgerMenu.svg";
import { LogoSvg } from "../../../assets/svgIcons";
import { selected_business_unit } from "../../../config/localStorage";
import { isNotificationEnabled } from "../../../helper/commonHelper";
import "../../../style/notificationCommonComp.scss";
import { alertApiEndpoint, logout } from "../../../utils/ApiHandler";
import {
  APPBAR_MENU,
  CARBON_ANALYTICS,
  DATA_MAPPING,
  DEMAND_PLANNING,
  ENTITIES_DATA,
  INVENTORY_PLANNING,
  PRODUCTION_PLANNING,
  PRODUCT_PLANNING,
  RAW_MATERIAL_PLANNING,
  RISK_MANAGEMENT_DIGITAL_TWIN_DATA,
  SNOP,
  SYSTEM_ADMIN,
  TRANSACTION_DATA,
  TRANSACTION_LOG,
  additionalRoutes,
  carbonAnalyticsMenu,
  dataMappingMenu,
  demandMenu,
  entitiesMenu,
  inventoryMenu,
  productMenu,
  productionMenu,
  rawMaterialMenu,
  riskDtrmMenu,
  snopMenu,
  transactionLogMenu,
  transactionMenu
} from "../../../utils/Constants";
// import EndPoint from "../../../utils/EndPoints";
import MultiAvatar from "../../MultiAvatar";
import { AppBar } from "../Layout/components/common";
import "./header.scss";

const Header = ({
  open,
  setOpen,
  isEnter,
  setIsEnter,
  mainMenu,
  setMainMenu,
}) => {
  const { IBP, EDM, ID, CX, DTRM } = APPBAR_MENU;
  const navigate = useNavigate();
  const user = JSON.parse(Cookies.get("user") || null);
  const user_metadata = JSON.parse(localStorage.getItem("user") || null);
  const permissions = get(user_metadata, "permissions", []);
  const firstName = get(user, "first_name", "");
  const lastName = get(user, "last_name", "");
  const userRole = get(user_metadata, "roles", []);
  const logo = get(user, "logo", null);
  const businessUnitName = selected_business_unit?.business_unit_name;
  const internalDashboard = SYSTEM_ADMIN.some((r) => permissions.includes(r));
  // const isAdmin = isAdminUser();

  const handleLogout = () => {
    logout();
    localStorage.clear();
    navigate("/");
  };

  const integratedBusiness =
    DEMAND_PLANNING.some((r) => permissions.includes(r)) ||
    SNOP.some((r) => permissions.includes(r)) ||
    INVENTORY_PLANNING.some((r) => permissions.includes(r)) ||
    PRODUCTION_PLANNING.some((r) => permissions.includes(r)) ||
    PRODUCT_PLANNING.some((r) => permissions.includes(r)) ||
    RAW_MATERIAL_PLANNING.some((r) => permissions.includes(r));

  const enterpriseData =
    ENTITIES_DATA.some((r) => permissions.includes(r)) ||
    TRANSACTION_DATA.some((r) => permissions.includes(r)) ||
    TRANSACTION_LOG.some((r) => permissions.includes(r));

  const carbonXData =
    CARBON_ANALYTICS.some((r) => permissions.includes(r)) ||
    DATA_MAPPING.some((r) => permissions.includes(r));

  const riskManagement = RISK_MANAGEMENT_DIGITAL_TWIN_DATA.some((r) =>
    permissions.includes(r)
  );

  useEffect(() => {
    internalDashboard && setMainMenu(ID);
  }, [internalDashboard]);

  const getPath = (mainMenu, permissions) => {
    const resultArray = [];

    mainMenu === ID &&
      additionalRoutes.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === IBP &&
      snopMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === IBP &&
      demandMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === IBP &&
      productMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === IBP &&
      inventoryMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === IBP &&
      productionMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === IBP &&
      rawMaterialMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === EDM &&
      entitiesMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === EDM &&
      transactionMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === EDM &&
      transactionLogMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === CX &&
      carbonAnalyticsMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === CX &&
      dataMappingMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    mainMenu === DTRM &&
      riskDtrmMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
          resultArray.push(item);
        }
      });

    return resultArray[0];
  };

  const handleTabChange = (tab) => {
    setMainMenu(tab);
    localStorage.setItem("mainMenu", JSON.stringify(tab));
    const path = getPath(tab, permissions);
    !isEmpty(path) && window.location.replace(path.path);
  };

  const handleDrawerOpen = () => {
    setOpen(prev => !prev);
  };

  // const handleAvatarClick = () => {
  //   if(isAdmin) return true;
  //   navigate(EndPoint.MY_PROFILE)
  // }

  return (
    <AppBar
      open={open}
      className={`header-container ${!open && "pd-lft"}`}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          disableRipple={true}
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 2,
            ...(open && { display: "none" }),
          }}
        >
          {/* <HamburgerMenuSvg /> */}
          <img src={HamburgerMenu} alt="logo" className="header-menu-drawer" />
        </IconButton>
        <div className={open ? "company-logo-open" : "company-logo-close"}>
          {!open &&
            (logo && !isEmpty(logo) ? (
              <img src={logo} alt="logo" className="header-logo" />
            ) : (
              <LogoSvg />
            ))}
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div className="header-button-section">
            {integratedBusiness && (
              <div>
                <button
                  className={`header-buttons ${mainMenu === IBP && "header-active brd-left"
                    } ${open === false && "mrg-left"}`}
                  onClick={() => handleTabChange(IBP)}
                >
                  {IBP}
                </button>
                {mainMenu === IBP && <div className="active-bar"></div>}
              </div>
            )}
            {enterpriseData && (
              <div>
                <button
                  className={`header-buttons ${mainMenu === EDM && "header-active"
                    }`}
                  onClick={() => handleTabChange(EDM)}
                >
                  {EDM}
                </button>
                {mainMenu === EDM && <div className="active-bar"></div>}
              </div>
            )}
            {carbonXData && (
              <div>
                <button
                  className={`header-buttons ${mainMenu === CX && "header-active"
                    }`}
                  onClick={() => handleTabChange(CX)}
                >
                  {CX}
                </button>
                {mainMenu === CX && <div className="active-bar"></div>}
              </div>
            )}
            {internalDashboard && (
              <div>
                <button
                  className={`header-buttons ${mainMenu === ID && "header-active"
                    }`}
                  onClick={() => handleTabChange(ID)}
                >
                  {ID}
                </button>
                {mainMenu === ID && <div className="active-bar"></div>}
              </div>
            )}
            {riskManagement && (
              <div>
                <button
                  className={`header-buttons ${mainMenu === DTRM && "header-active"
                    }`}
                  onClick={() => handleTabChange(DTRM)}
                >
                  {DTRM}
                </button>
                {mainMenu === DTRM && <div className="active-bar"></div>}
              </div>
            )}
          </div>

          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div className="header-content">
              <h2 className="common-heading">{""}</h2>
              <div className="header-logout-container">
                {isNotificationEnabled() && <Notification baseUrl={alertApiEndpoint} />}
                <div className="header-logout">
                  <AiOutlineExpand
                    className="header-logout-logo"
                    onClick={() => {
                      setIsEnter(!isEnter);
                    }}
                  />
                </div>
                <MultiAvatar />
                {/* <Avatar
                  name={`${firstName} ${lastName}`}
                  size="40"
                  textSizeRatio={1}
                  textMarginRatio={0.25}
                  round
                  className={["me-2", isAdmin ? "" : "cursor-pointer"].join(" ")}
                  onClick={handleAvatarClick}
                />
                <div onClick={handleAvatarClick} className={["header-logout-user", isAdmin ? "" : "cursor-pointer"].join(" ")}>
                  <span className="header-user-name">{`${firstName + " " + lastName
                    }`}</span>
                  <span>
                    {`${businessUnitName}`} {businessUnitName && "|"}{" "}
                    {userRole[0]}{" "}
                  </span>
                </div> */}

                <div className="header-logout">
                  <AiOutlineLogout
                    className="header-logout-logo"
                    onClick={handleLogout}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
