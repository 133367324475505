import React from "react";
export const PersonalIcon = () => {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
			<path  d="M28.2,12.2c-0.3-1.7-1-3.2-1.9-4.7L27.2,6l-3.3-3.3l-1.5,0.9c-1.1-0.7-2.2-1.3-3.5-1.6C18,0.8,16.6,0,15,0
	s-3,0.8-4,2C9.7,2.4,8.6,2.9,7.5,3.7L6,2.8L2.7,6l0.9,1.5c-1,1.4-1.6,3-1.9,4.7L0,12.7v4.6l1.7,0.4c0.3,1.7,1,3.2,1.9,4.7L2.7,24
	L6,27.2l1.5-0.9c1.4,1,3,1.6,4.7,1.9l0.4,1.7h4.6l0.4-1.7c1.7-0.3,3.2-1,4.7-1.9l1.5,0.9l3.3-3.3l-0.9-1.5c1-1.4,1.6-3,1.9-4.7
	l1.7-0.4v-4.6L28.2,12.2z M20.4,5.8c3.2,1.9,5.2,5.4,5.2,9.1c0,2.4-0.8,4.6-2.2,6.4c-0.3-0.3-0.7-0.6-1.1-0.7
	c0.5-0.4,0.8-1.1,0.8-1.8c0-0.8-0.4-1.5-1-1.9v-1.2c0-1.5-1-2.9-2.5-3.3l-2.8-0.8v-0.9c0.3-0.3,0.6-0.7,0.8-1.2h0.6
	c0.8,0,1.4-0.6,1.4-1.4v-2C20,6.1,20.2,6,20.4,5.8z M10.1,6.2v2c0,0.8,0.6,1.4,1.4,1.4h0.7c0.1,0.5,0.4,0.9,0.8,1.2v0.9l-2.8,0.8
	c-1.4,0.4-2.5,1.7-2.5,3.3v1.1c-0.6,0.4-1,1.1-1,1.9c0,0.7,0.3,1.4,0.8,1.8c-0.4,0.2-0.8,0.4-1.1,0.7c-1.4-1.7-2.2-4-2.2-6.4
	c0-3.8,2-7.2,5.2-9.1C9.7,6,9.9,6.1,10.1,6.2z M17.9,7.7h0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-0.5V7.7z M18.8,6.9
	c-0.1,0-0.3-0.1-0.5-0.1h-0.5V6.3h1V6.9z M19.3,18.8c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
	S19.3,19.7,19.3,18.8z M21.2,16.5c-0.1,0-0.3,0-0.5,0c-1.4,0-2.4,1.1-2.4,2.4c0,0.7,0.3,1.4,0.8,1.8c-0.5,0.2-0.9,0.5-1.3,1
	c-0.3-0.4-0.8-0.7-1.3-1c0.5-0.4,0.8-1.1,0.8-1.8c0-1.4-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4c0,0.7,0.3,1.4,0.8,1.8
	c-0.5,0.2-1,0.6-1.3,1c-0.4-0.4-0.8-0.7-1.3-1c0.5-0.4,0.8-1.1,0.8-1.8c0-1.4-1.1-2.4-2.4-2.4c-0.1,0-0.3,0-0.5,0v-0.7
	c0-1.1,0.7-2,1.8-2.3L12,13l0.8,3.9l2.2-2.2l2.2,2.2l0.8-3.9l1.5,0.4c1.1,0.3,1.8,1.3,1.8,2.3C21.2,15.7,21.2,16.5,21.2,16.5z
	 M13.5,18.8c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4C14.1,20.3,13.5,19.7,13.5,18.8z M15,21.3
	c0.1,0,0.3,0,0.5,0c0,0.6-0.3,0.9-0.5,0.9s-0.5-0.3-0.5-0.9C14.6,21.3,14.8,21.3,15,21.3z M7.7,18.8c0-0.8,0.6-1.4,1.4-1.4
	c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4C8.3,20.3,7.7,19.7,7.7,18.8z M12.1,8.7h-0.5c-0.3,0-0.5-0.2-0.5-0.5
	c0-0.3,0.2-0.5,0.5-0.5h0.5V8.7z M13.3,12.6l1,1.4l-1,1l-0.4-2.2L13.3,12.6z M16.6,15l-1-1l1-1.4l0.4,0.1L16.6,15z M15.9,11.9
	l-1,1.3l-1-1.3v-0.5c0.3,0.1,0.6,0.2,1,0.2s0.7,0,1-0.2V11.9z M15,10.6c-1.1,0-1.9-0.9-1.9-1.9V6.3h3.9v2.4
	C16.9,9.8,16,10.6,15,10.6z M12.1,6.8h-0.5c-0.2,0-0.3,0-0.5,0.1V6.3h1V6.8z M9.2,21.3c0.9,0,1.8,0.4,2.3,1.2
	c-0.2,0.5-0.4,1.1-0.4,1.7v0.7c-1.5-0.6-2.9-1.5-4-2.8C7.7,21.5,8.4,21.3,9.2,21.3z M12.1,24.2c0-1.1,0.6-2,1.5-2.5
	c0.1,0.9,0.7,1.5,1.4,1.5c0.7,0,1.3-0.7,1.4-1.5c0.9,0.5,1.5,1.4,1.5,2.5v1.1c-0.9,0.2-1.9,0.4-2.9,0.4s-2-0.1-2.9-0.4V24.2z
	 M18.4,22.5c0.5-0.7,1.4-1.2,2.3-1.2c0.8,0,1.5,0.3,2.1,0.9c-1.1,1.2-2.5,2.1-4,2.8v-0.7C18.8,23.5,18.7,22.9,18.4,22.5z M19.3,5.3
	h-8.7c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h8.7c0.3,0,0.5,0.2,0.5,0.5S19.6,5.3,19.3,5.3z M15,1c1.6,0,3,1,3.6,2.4h-7.1
	C12,1.9,13.4,1,15,1z M29,16.5l-1.6,0.4l0,0.3c-0.3,1.7-1,3.4-2,4.9l-0.2,0.3l0.9,1.4L23.7,26l-1.4-0.9l-0.2,0.2
	c-1.4,1-3.1,1.7-4.9,2l-0.3,0L16.5,29h-3.1L13,27.4l-0.3,0c-1.7-0.3-3.4-1-4.9-2l-0.3-0.2L6.2,26l-2.2-2.2l0.9-1.4l-0.2-0.2
	c-1-1.4-1.7-3.1-2-4.9l0-0.3l-1.6-0.4v-3.1L2.5,13l0-0.3c0.3-1.7,1-3.4,2-4.9l0.2-0.2L3.9,6.2L6.2,4l1.4,0.9l0.2-0.2
	c0.8-0.5,1.6-1,2.6-1.4c0,0,0,0.1,0,0.1c-0.6,0-1.2,0.6-1.2,1.4c0,0,0,0,0,0.1C5.6,7,3.4,10.8,3.4,15c0,6.4,5.2,11.6,11.6,11.6
	S26.5,21.4,26.5,15c0-4.2-2.2-8-5.8-10c0,0,0,0,0-0.1c0-0.7-0.5-1.3-1.2-1.4c0,0,0-0.1,0-0.1c0.9,0.3,1.8,0.8,2.6,1.4l0.2,0.2
	L23.7,4l2.2,2.2l-0.8,1.4l0.2,0.2c1,1.4,1.7,3.1,2,4.9l0,0.3l1.6,0.4V16.5z"/>
<path  d="M7.6,13.1c0.3-1.5,1.1-2.8,2.2-3.9L9.3,8.6C8,9.8,7.2,11.3,6.8,13L7.6,13.1z"/>
<path  d="M6.6,14.7h0.8c0-0.2,0-0.5,0-0.8l-0.8-0.1C6.6,14.1,6.6,14.4,6.6,14.7z"/>
			</g>
		</svg>
	);
};
