import React from "react";
export const BudgetIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.5,51v-5.6c0-2.7-2.2-4.9-4.9-5c3.1-4,4.9-9.1,4.9-14.5c0-13.3-10.8-24.1-24.1-24.1S47.3,12.6,47.3,25.9
   c0,0.9,0,1.8,0.1,2.7H8.6c-3.3,0-6,2.7-6,6v55.6c0,3.3,2.7,6,6,6h44.2c1.7,0,3.2-0.7,4.3-1.8h33.4c2.7,0,5-2.2,5-5v-5.6
   c0-1.4-0.6-2.7-1.5-3.6c0.9-0.9,1.5-2.2,1.5-3.6V71c0-1.4-0.6-2.7-1.5-3.6c0.9-0.9,1.5-2.2,1.5-3.6v-5.6c0-1.4-0.6-2.7-1.5-3.6
   C94.9,53.7,95.5,52.4,95.5,51z M50.1,25.9c0-11.7,9.6-21.3,21.3-21.3s21.3,9.6,21.3,21.3s-9.6,21.3-21.3,21.3
   c-4.6,0-8.9-1.4-12.6-4.1v-5.3c3.2,3.4,7.6,5.4,12.6,5.4c9.5,0,17.3-7.8,17.3-17.3c0-9.5-7.8-17.3-17.3-17.3s-17.3,7.8-17.3,17.3
   c0,1,0.1,1.9,0.2,2.9c-0.5-0.1-1-0.2-1.6-0.2h-2.5C50.1,27.7,50.1,26.8,50.1,25.9z M56.8,25.9c0-8,6.5-14.5,14.5-14.5
   s14.5,6.5,14.5,14.5s-6.5,14.5-14.5,14.5C63.4,40.4,56.8,33.9,56.8,25.9z M56,90.2c0,1.8-1.5,3.3-3.3,3.3H8.6
   c-1.8,0-3.3-1.5-3.3-3.3V34.6c0-1.8,1.5-3.3,3.3-3.3h44.2c1.8,0,3.3,1.5,3.3,3.3V90.2z M92.7,83.8v5.6c0,1.2-1,2.2-2.2,2.2H58.6
   c0.1-0.5,0.2-1,0.2-1.5v-8.6h31.7C91.7,81.6,92.7,82.6,92.7,83.8z M92.7,71v5.6c0,1.2-1,2.2-2.2,2.2H58.8v-10h31.7
   C91.7,68.8,92.7,69.8,92.7,71z M92.7,58.2v5.6c0,1.2-1,2.2-2.2,2.2H58.8V56h31.7C91.7,56,92.7,57,92.7,58.2z M58.8,53.2v-6.8
   c3.8,2.3,8.1,3.5,12.6,3.5c6.5,0,12.4-2.6,16.7-6.8h2.4c1.2,0,2.2,1,2.2,2.2V51c0,1.2-1,2.2-2.2,2.2H58.8z M65.8,22
   c0-2.6,1.7-4.6,4.2-5.1v-1.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4V17c2.2,0.6,3.9,2.6,3.9,5.1c0,0.8-0.6,1.4-1.4,1.4
   s-1.4-0.6-1.4-1.4c0-1.4-1.1-2.5-2.5-2.5c-1.3,0-2.8,0.6-2.8,2.5s1.5,2.5,2.8,2.5c3.2,0,5.6,2.2,5.6,5.2c0,2.6-1.7,4.6-4.2,5.1v1.4
   c0,0.8-0.6,1.4-1.4,1.4S70,37,70,36.2v-1.4c-2.2-0.6-3.9-2.6-3.9-5.1c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4c0,1.4,1.1,2.5,2.5,2.5
   c1.3,0,2.8-0.6,2.8-2.5s-1.5-2.5-2.8-2.5C68.1,27.3,65.8,25.1,65.8,22z M48.7,34.9h-36c-1.8,0-3.2,1.5-3.2,3.2V45
   c0,1.8,1.5,3.2,3.2,3.2h36c1.8,0,3.2-1.5,3.2-3.2v-6.8C51.9,36.4,50.5,34.9,48.7,34.9z M49.1,45c0,0.3-0.2,0.5-0.5,0.5h-36
   c-0.3,0-0.5-0.2-0.5-0.5v-6.8c0-0.3,0.2-0.5,0.5-0.5h36c0.3,0,0.5,0.2,0.5,0.5V45z M18.3,50.5h-5.6c-1.8,0-3.2,1.5-3.2,3.2v5.6
   c0,1.8,1.5,3.2,3.2,3.2h5.6c1.8,0,3.2-1.5,3.2-3.2v-5.6C21.5,51.9,20.1,50.5,18.3,50.5z M18.8,59.3c0,0.3-0.2,0.5-0.5,0.5h-5.6
   c-0.3,0-0.5-0.2-0.5-0.5v-5.6c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5V59.3z M18.3,64.8h-5.6c-1.8,0-3.2,1.5-3.2,3.2v5.6
   c0,1.8,1.5,3.2,3.2,3.2h5.6c1.8,0,3.2-1.5,3.2-3.2V68C21.5,66.2,20.1,64.8,18.3,64.8z M18.8,73.6c0,0.3-0.2,0.5-0.5,0.5h-5.6
   c-0.3,0-0.5-0.2-0.5-0.5V68c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5V73.6z M18.3,79.1h-5.6c-1.8,0-3.2,1.5-3.2,3.2V88
   c0,1.8,1.5,3.2,3.2,3.2h5.6c1.8,0,3.2-1.5,3.2-3.2v-5.6C21.5,80.6,20.1,79.1,18.3,79.1z M18.8,88c0,0.3-0.2,0.5-0.5,0.5h-5.6
   c-0.3,0-0.5-0.2-0.5-0.5v-5.6c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5V88z M33.5,50.5h-5.6c-1.8,0-3.2,1.5-3.2,3.2v5.6
   c0,1.8,1.5,3.2,3.2,3.2h5.6c1.8,0,3.2-1.5,3.2-3.2v-5.6C36.7,51.9,35.3,50.5,33.5,50.5z M33.9,59.3c0,0.3-0.2,0.5-0.5,0.5h-5.6
   c-0.3,0-0.5-0.2-0.5-0.5v-5.6c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5C33.9,53.7,33.9,59.3,33.9,59.3z M33.5,64.8h-5.6
   c-1.8,0-3.2,1.5-3.2,3.2v5.6c0,1.8,1.5,3.2,3.2,3.2h5.6c1.8,0,3.2-1.5,3.2-3.2V68C36.7,66.2,35.3,64.8,33.5,64.8z M33.9,73.6
   c0,0.3-0.2,0.5-0.5,0.5h-5.6c-0.3,0-0.5-0.2-0.5-0.5V68c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5
   C33.9,68,33.9,73.6,33.9,73.6z M33.5,79.1h-5.6c-1.8,0-3.2,1.5-3.2,3.2V88c0,1.8,1.5,3.2,3.2,3.2h5.6c1.8,0,3.2-1.5,3.2-3.2v-5.6
   C36.7,80.6,35.3,79.1,33.5,79.1z M33.9,88c0,0.3-0.2,0.5-0.5,0.5h-5.6c-0.3,0-0.5-0.2-0.5-0.5v-5.6c0-0.3,0.2-0.5,0.5-0.5h5.6
   c0.3,0,0.5,0.2,0.5,0.5C33.9,82.4,33.9,88,33.9,88z M48.7,50.5H43c-1.8,0-3.2,1.5-3.2,3.2v5.6c0,1.8,1.5,3.2,3.2,3.2h5.6
   c1.8,0,3.2-1.5,3.2-3.2v-5.6C51.9,51.9,50.5,50.5,48.7,50.5z M49.1,59.3c0,0.3-0.2,0.5-0.5,0.5H43c-0.3,0-0.5-0.2-0.5-0.5v-5.6
   c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5V59.3z M48.7,64.8H43c-1.8,0-3.2,1.5-3.2,3.2V88c0,1.8,1.5,3.2,3.2,3.2h5.6
   c1.8,0,3.2-1.5,3.2-3.2V68C51.9,66.2,50.5,64.8,48.7,64.8z M49.1,88c0,0.3-0.2,0.5-0.5,0.5H43c-0.3,0-0.5-0.2-0.5-0.5V68
   c0-0.3,0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5V88z"
      />
    </svg>
  );
};
