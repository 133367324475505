import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Collapse, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Cookies from 'js-cookie';
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import HamburgerMenu from "../../../assets/hamburgerMenu.svg";
import {
    LogoSvg
} from "../../../assets/svgIcons";
import { matchPathName } from "../../../helper/commonHelper";
import {
    APPBAR_MENU,
    CARBON_ANALYTICS,
    CarbonXMenu,
    DATA_MAPPING,
    DEMAND_PLANNING,
    EDMMenu,
    ENTITIES_DATA,
    IBPMenu,
    INVENTORY_PLANNING,
    PRODUCTION_PLANNING,
    PRODUCT_PLANNING,
    RAW_MATERIAL_PLANNING,
    RISK_MANAGEMENT_DIGITAL_TWIN_DATA,
    RiskAIMenu,
    SNOP,
    SUPPLYSubMenu,
    SUPPLY_PLANNING,
    SUPPLY_PLAN_REPORTS,
    SYSTEM_ADMIN,
    TRANSACTION_DATA,
    TRANSACTION_LOG,
    activateSNOPMessage,
    carbonAnalyticsMenu,
    dataMappingMenu,
    demandMenu,
    entitiesMenu,
    inventoryMenu,
    mainMenus,
    productMenu,
    productionMenu,
    rawMaterialMenu,
    riskDtrmMenu,
    sideBarMenu,
    snopMenu,
    transactionLogMenu,
    transactionMenu
} from "../../../utils/Constants";
import { Drawer, DrawerHeader } from "../Layout/components/common";
import "./sidebar.scss";

const Sidebar = ({ open, setOpen, mainMenu, isMyProfile }) => {
    const location = useLocation();
    const { IBP, EDM, ID, CX, DTRM } = APPBAR_MENU;
    const user = JSON.parse(Cookies.get("user") || null);
    const user_metadata = JSON.parse(localStorage.getItem("user") || null);
    const snop = JSON.parse(localStorage.getItem("snop"));
    const permissions = get(user_metadata, "permissions", []);
    const roles = get(user_metadata, "roles", [])
    const logo = get(user, "logo", null);;
    const configuration = JSON.parse(localStorage.getItem("configuration"));
    const foreCastActive = configuration?.forecast?.isDCActive;

    const [, setSelectedDrawerMenu] = useState("");
    const [snopOpen,] = useState(true);
    const [demandOpen, setDemandOpen] = useState(false);
    const [supplyOpen, setIsSupplyOpen] = useState(false);
    const [productOpen, setIsProductOpen] = useState(false);
    const [isInventoryOpen, setIsInventoryOpen] = useState(false);
    const [isProductionOpen, setIsProductionOpen] = useState(false);
    const [isRawMaterialOpen, setIsRawMaterialOpen] = useState(false);
    const [entityOpen, setEntityOpen] = useState(true);
    const [transactionOpen, setTransactionOpen] = useState(false);
    const [transactionLogOpen, setTransactionLogOpen] = useState(false);
    const [carbonOpen, setCarbonOpen] = useState(true);
    const [dataMappingOpen, setDataMappingOpen] = useState(false);
    const [riskOpen, setRiskOpen] = useState(true);
    // for my profile page 

    useEffect(() => { //On mount, rehydrate the opened sidebar.
        const findOpenedMenu = (actualData) => actualData.find(subMenu => matchPathName(subMenu.subMenuPaths, location.pathname));
        handleDrawerClose();  // Close all the menus
        setOpen(true);
        let subMenuOpened = "";
        // Then open the sumenu which matches current location.
        for (let i = 0; i < mainMenus.length; i++) {
            subMenuOpened = findOpenedMenu(mainMenus[i]);
            if (subMenuOpened) break;
        }
        if (subMenuOpened?.title === "Supply Planning") {
            subMenuOpened = findOpenedMenu(SUPPLYSubMenu);
        }
        if (subMenuOpened) handleDrawerMenuClick(subMenuOpened);
    }, []);

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const isEmptySNOP = isEmpty(snop);

    useEffect(() => {
        if (isEmptySNOP) {
            setDemandOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snop, setDemandOpen, setIsSupplyOpen, setIsProductOpen, localStorage.getItem("snop")]);

    useEffect(() => {
        if (open) {
            if (!isEmptySNOP) {
                if (SUPPLYSubMenu.find(el => matchPathName(el.subMenuPaths, location.pathname))) {
                    setDemandOpen(false);
                    setIsSupplyOpen(true);
                    setIsProductOpen(true);
                } else {
                    setDemandOpen(true);
                    setRiskOpen(true)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleDrawerClose = () => {
        setDemandOpen(false);
        setIsSupplyOpen(false);
        setIsProductOpen(false);
        setEntityOpen(false);
        setTransactionLogOpen(false);
        setTransactionOpen(false);
        setCarbonOpen(false);
        setDataMappingOpen(false);
        setRiskOpen(false);
        setOpen(prev => !prev);
    };

    const handleDrawerMenuClick = (item) => {
        setSelectedDrawerMenu(item.title);
        if (item.title === "Demand Planning") {
            if (isEmptySNOP) {
                toast.error(activateSNOPMessage)
            } else {
                !open && setOpen(true);
                setDemandOpen(prev => !prev);
                setIsSupplyOpen(false);
                setIsProductOpen(false);
            }
        }
        if (item.title === "Supply Planning") {
            if (isEmptySNOP) {
                toast.error(activateSNOPMessage)
            } else {
                !open && setOpen(true);
                setDemandOpen(false);
                setIsSupplyOpen(prev => !prev);
                setIsProductOpen(false);
                setIsInventoryOpen(false)
                setIsProductionOpen(false)
                setIsRawMaterialOpen(false)
            }
        }
        if (item.title === "Product Planning") {
            setDemandOpen(false);
            setIsSupplyOpen(false);
            setIsProductOpen(prev => !prev);
            setIsRawMaterialOpen(false)
            setIsProductionOpen(false)
            setIsInventoryOpen(!isInventoryOpen)
        }
        if (item.title === "Inventory") {
            setDemandOpen(false);
            setIsSupplyOpen(true);
            setIsProductOpen(false);
            setIsRawMaterialOpen(false)
            setIsProductionOpen(false)
            setIsInventoryOpen(!isInventoryOpen)
        }
        if (item.title === "Production") {
            setIsRawMaterialOpen(false)
            setIsInventoryOpen(false)
            setIsProductionOpen(prev => !prev)
        }
        if (item.title === "Raw Material") {
            setIsProductionOpen(false)
            setIsInventoryOpen(false)
            setIsRawMaterialOpen(prev => !prev)
        }
        if (item.title === "Entities Data") {
            !open && setOpen(true);
            setTransactionLogOpen(false)
            setTransactionOpen(false)
            setEntityOpen(prev => !prev)
        }
        if (item.title === "Transaction Data") {
            !open && setOpen(true);
            setEntityOpen(false)
            setTransactionLogOpen(false)
            setTransactionOpen(prev => !prev)
        }
        if (item.title === "Transaction Log") {
            !open && setOpen(true);
            setEntityOpen(false)
            setTransactionOpen(false)
            setTransactionLogOpen(prev => !prev)
        }
        if (item.title === "Carbon Analytics") {
            !open && setOpen(true);
            setDataMappingOpen(false)
            setCarbonOpen(prev => !prev)
        }
        if (item.title === "Data Mapping") {
            !open && setOpen(true);
            setCarbonOpen(false)
            setDataMappingOpen(prev => !prev)
        }
        if (item.title === "Reports") {
            !open && setOpen(true);
            setDemandOpen(false);
            setIsSupplyOpen(prev => !prev);
            setIsProductOpen(false);
            setIsInventoryOpen(false)
            setIsProductionOpen(false)
            setIsRawMaterialOpen(false)
        }
        if (item.title === "Risk AI") {
            !open && setOpen(true);
            setRiskOpen(prev => !prev);
        }
    };

    const validateMenuPermission = (item) => {
        if (item.title === "Entities Data")
            return ENTITIES_DATA.some((r) => permissions.includes(r));
        if (item.title === "Transaction Data")
            return TRANSACTION_DATA.some((r) => permissions.includes(r));
        if (item.title === "Transaction Log")
            return TRANSACTION_LOG.some((r) => permissions.includes(r));
        if (item.title === "Demand Planning")
            return DEMAND_PLANNING.some((r) => permissions.includes(r));
        if (item.title === "Product Planning")
            return PRODUCT_PLANNING.some((r) => permissions.includes(r));
        if (item.title === "Supply Planning")
            return SUPPLY_PLANNING.some((r) => permissions.includes(r));
        if (item.title === "Carbon Analytics")
            return CARBON_ANALYTICS.some((r) => permissions.includes(r));
        if (item.title === "Data Mapping")
            return DATA_MAPPING.some((r) => permissions.includes(r));
        if (item.title === "Inventory")
            return INVENTORY_PLANNING.some((r) => permissions.includes(r));
        if (item.title === "Production")
            return PRODUCTION_PLANNING.some((r) => permissions.includes(r));
        if (item.title === "Raw Material")
            return RAW_MATERIAL_PLANNING.some((r) => permissions.includes(r));
        if (item.title === "Reports")
            return SUPPLY_PLAN_REPORTS.some((r) => permissions.includes(r));
        if (item.title === "Risk AI")
            return RISK_MANAGEMENT_DIGITAL_TWIN_DATA.some((r) => permissions.includes(r));
    }

    function collapsibleSubMenu(menuState, menuArr) {
        return (
            <Collapse in={menuState} timeout="auto">
                <List
                    component="div"
                    className='sidebar-list'
                    disablePadding>
                    {
                        menuArr?.map((item, index) => {
                            let showMenu = validateMenuPermission(item);
                            const IconSvg = item?.icon;
                            if (item?.permission?.some((r) => permissions.includes(r)) || showMenu) {
                                if (menuArr.findIndex(el => el.title === "Forecasting") >= 0) {
                                    return item.permission === demandMenu[0].permission && foreCastActive ? (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            className={`sidebar-menu-list`}
                                            sx={{
                                                ...(location.pathname === item?.path && open && {
                                                    borderLeft: "5px solid #E94F1C",
                                                }),
                                            }}
                                        >
                                            <ListItemButton
                                                component="a"
                                                href={item.path}
                                                className={`sidebar-menu-subbutton ${location.pathname === item.childPath && "active-subbutton"}`}
                                                onClick={() => handleDrawerMenuClick(item)}
                                            >
                                                <Box className={location.pathname === item.childPath ? "primary-color-class" : "secondary-color-class"}>
                                                    <IconSvg className="sidebar-menu-icon" />
                                                </Box>
                                                <ListItemText
                                                    primary={item?.title}
                                                    className={`sidebar-menu-text  ${location.pathname === item?.path ? 'active-text' : ''}`}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ) : (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            className={`sidebar-menu-list`}
                                            sx={{
                                                ...(location.pathname === item?.path && {
                                                    borderLeft: "5px solid #E94F1C",
                                                }),
                                            }}
                                        >
                                            <ListItemButton
                                                component="a"
                                                href={item.path}
                                                className={`sidebar-menu-subbutton ${location.pathname === item.childPath && "active-subbutton"}`}
                                                onClick={() => handleDrawerMenuClick(item)}
                                            >
                                                <Box className={location.pathname === item.childPath ? "primary-color-class" : "secondary-color-class"}>
                                                    <IconSvg className="sidebar-menu-icon" />
                                                </Box>
                                                <ListItemText
                                                    primary={item?.title}
                                                    className={`sidebar-menu-text  ${location.pathname === item?.path ? 'active-text' : ''}`}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                } else {
                                    return (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            className={`sidebar-menu-list`}
                                            sx={{
                                                ...(location.pathname === item?.path && {
                                                    borderLeft: "5px solid #E94F1C",
                                                }),
                                            }}
                                        >
                                            <ListItemButton
                                                component="a"
                                                href={item.path}
                                                className={`sidebar-menu-subbutton ${location.pathname === item.childPath || matchPathName(item.subMenuPaths, location.pathname) && "active-subbutton"}`}
                                                onClick={() => handleDrawerMenuClick(item)}
                                            >
                                                <Box className={location.pathname === item.childPath || matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-class" : "secondary-color-class"}>
                                                    {IconSvg && <IconSvg className="sidebar-menu-icon" />}
                                                </Box>
                                                <ListItemText
                                                    primary={item?.title}
                                                    className={`sidebar-menu-text  ${location.pathname === item?.path || matchPathName(item.subMenuPaths, location.pathname) ? 'active-text' : ''}`}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                />
                                                <Box>
                                                    {
                                                        ((JSON.stringify(menuArr) === JSON.stringify(SUPPLYSubMenu)) && open) && (item.title === "Inventory" ? (
                                                            (isInventoryOpen ? <ExpandLess
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}

                                                            /> : <ExpandMore
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            />)
                                                        ) : (item.title === "Production") ? (
                                                            (isProductionOpen ? <ExpandLess
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}

                                                            /> : <ExpandMore
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            />)
                                                        ) : (item.title === "Raw Material") ? (
                                                            (isRawMaterialOpen ? <ExpandLess
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            /> : <ExpandMore
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            />)
                                                        ) : null)
                                                    }
                                                </Box>
                                            </ListItemButton>
                                            {
                                                JSON.stringify(menuArr) === JSON.stringify(SUPPLYSubMenu) && open &&
                                                (
                                                    item.title === "Inventory" ?
                                                        (collapsibleSubMenu(
                                                            isInventoryOpen,
                                                            inventoryMenu
                                                        )) : (item.title === "Production") ?
                                                            (collapsibleSubMenu(
                                                                isProductionOpen,
                                                                productionMenu
                                                            )) : (item.title === "Raw Material") ?
                                                                (collapsibleSubMenu(
                                                                    isRawMaterialOpen,
                                                                    rawMaterialMenu
                                                                )) : null
                                                )
                                            }
                                        </ListItem>
                                    )
                                }
                            }
                            return null;
                        })
                    }
                </List>
            </Collapse>
        );
    }

    const _renderSystemAdminMenu = () => {
        return (
            <>
                <List className='sidebar-list'>

                    {
                        sideBarMenu.map((item, index) => {
                            if (
                                (item?.permission?.some((r) => permissions.includes(r)) &&
                                    item.roles.length === 0) ||
                                roles.includes(item.roles[0])
                            ) {
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        className={`sidebar-menu-list`}
                                        sx={{
                                            ...(location.pathname === item.childPath && {
                                                borderLeft: "5px solid #E94F1C",
                                            }),
                                        }}
                                    >
                                        <ListItemButton
                                            component="a"
                                            href={item.path}
                                            className={`sidebar-menu-button ${location.pathname === item.childPath && "active-button"}`}
                                        >
                                            <Box>
                                                <i className={`${item.icon} sidebar-menu-icon ${location.pathname === item.childPath ? 'active-text' : ''}`}></i>{" "}
                                            </Box>
                                            <ListItemText
                                                primary={item?.title}
                                                className={`sidebar-menu-text ${location.pathname === item.childPath ? 'active-text' : ''}`}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                            return null;
                        })
                    }
                </List>
            </>
        );
    };

    const _renderSNOPMenu = () => {
        return (
            <>
                <List className='sidebar-list'>
                    {
                        snopOpen && (
                            snopMenu?.map((item, index) => {
                                const { icon: IconSvg } = item;
                                if (item.permission.some((r) => permissions.includes(r))) {
                                    return (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            className={`sidebar-menu-list`}
                                            sx={{
                                                ...(location.pathname === item.childPath && {
                                                    borderLeft: "5px solid #E94F1C",
                                                }),
                                            }}
                                        >
                                            <ListItemButton
                                                component="a"
                                                href={item.path}
                                                className={`sidebar-menu-button ${location.pathname === item.childPath && "active-button"}`}
                                            >
                                                <Box className={location.pathname === item.childPath ? "primary-color-class" : "secondary-color-class"}>
                                                    <IconSvg className="sidebar-menu-icon" />
                                                </Box>
                                                <ListItemText
                                                    primary={item?.title}
                                                    className={`sidebar-menu-text ${location.pathname === item.childPath ? 'active-text' : ''}`}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                                return null;
                            })
                        )
                    }
                </List>
                {/* {snopOpen && (
                    <div className="sidebar-menu-container">
                        <ul className="sidebar-menu-list m-0 p-0">
                            {snopMenu.map((item, index) => {
                                const { icon: IconSvg } = item;
                                if (permissions.includes(item.permission))
                                    return (
                                        <a
                                            href={item.path}
                                            key={index}
                                            className={
                                                location.pathname === item.childPath ? "active" : ""
                                            }
                                        >
                                            <li
                                                className={`sidebar-menu-item ${location.pathname === item.childPath && "active"
                                                    } `}
                                            >
                                                <IconSvg  color={primaryColor} />
                                                <span>{item.title}</span>{" "}
                                            </li>
                                        </a>
                                    );
                                return null;
                            })}
                        </ul>
                    </div>
                )} */}
            </>
        );
    };

    const _renderDPAIMenu = () => {
        return (
            <>
                <List className='sidebar-list'>
                    {
                        IBPMenu?.map((item, index) => {
                            let showMenu = validateMenuPermission(item);
                            if (showMenu) {
                                const { icon: IconSvg } = item;
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        className={`sidebar-menu-list`}
                                        sx={{
                                            ...(matchPathName(item.subMenuPaths, location.pathname) && !open && {
                                                borderLeft: "5px solid #E94F1C",
                                            }),
                                        }}
                                    >
                                        <ListItemButton
                                            className={`sidebar-menu-button ${location.pathname === item.childPath && "active-button"}`}
                                            onClick={() => handleDrawerMenuClick(item)}
                                        >
                                            <Box className={`${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-class" : "secondary-color-class"}`}>
                                                <IconSvg className="sidebar-menu-icon" />
                                            </Box>
                                            <ListItemText
                                                primary={item?.title}
                                                className={`sidebar-menu-text ${isEmptySNOP && ["Demand Planning", "Supply Planning"].includes(item.title) && "disabled-menu"} ${matchPathName(item.subMenuPaths, location.pathname) ? 'active-text' : ""}`}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                }}
                                            />
                                            <Box>
                                                {
                                                    open &&
                                                    item.title === "Product Planning" && (
                                                        (demandOpen ?
                                                            <ExpandLess
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            /> : <ExpandMore
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            />)
                                                    )
                                                }
                                                {
                                                    open &&
                                                    item.title === "Demand Planning" && (
                                                        (demandOpen ?
                                                            <ExpandLess
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            /> : <ExpandMore
                                                                className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                            />)
                                                    )
                                                }
                                                {
                                                    open &&
                                                    item.title === "Supply Planning" && (
                                                        (supplyOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}

                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}

                                                        />)
                                                    )
                                                }
                                            </Box>
                                        </ListItemButton>
                                        {
                                            item.title === "Product Planning" &&
                                            collapsibleSubMenu(
                                                productOpen,
                                                productMenu
                                            )
                                        }
                                        {
                                            item.title === "Demand Planning" &&
                                            collapsibleSubMenu(
                                                demandOpen,
                                                demandMenu
                                            )
                                        }
                                        {
                                            item.title === "Supply Planning" &&
                                            collapsibleSubMenu(
                                                supplyOpen,
                                                SUPPLYSubMenu
                                            )
                                        }
                                    </ListItem>
                                )
                            }
                            return null;
                        })
                    }
                </List>
            </>
        );
    }

    const _renderEDMMenu = () => {
        return (
            <>
                <List className='sidebar-list'>
                    {
                        EDMMenu.map((item, index) => {
                            let showMenu = validateMenuPermission(item);
                            if (showMenu) {
                                const { icon: IconSvg } = item;
                                const isActive = matchPathName(item.subMenuPaths, location.pathname);
                                const isCurrentPath = location.pathname === item.childPath;
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        className={`sidebar-menu-list`}
                                        sx={{
                                            ...(isActive && !open && {
                                                borderLeft: "5px solid #E94F1C",
                                            }),
                                        }}
                                    >
                                        <ListItemButton
                                            className={`sidebar-menu-button ${isCurrentPath && "active-button"}`}
                                            onClick={() => handleDrawerMenuClick(item)}
                                        >
                                            <Box className={isActive ? "primary-color-class" : "secondary-color-class"}>
                                                <IconSvg className="sidebar-menu-icon" />
                                            </Box>
                                            <ListItemText
                                                primary={item?.title}
                                                className={`sidebar-menu-text ${isActive ? 'active-text' : ""}`}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                }}
                                            />
                                            <Box>
                                                {
                                                    open &&
                                                    item.title === "Entities Data" && (
                                                        (entityOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${isActive ? "primary-color-container" : "text-color-container"}`}
                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${isActive ? "primary-color-container" : "text-color-container"}`}
                                                        />)
                                                    )
                                                }
                                                {
                                                    open &&
                                                    item.title === "Transaction Data" && (
                                                        (transactionOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${isActive ? "primary-color-container" : "text-color-container"}`}
                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${isActive ? "primary-color-container" : "text-color-container"}`}

                                                        />)
                                                    )
                                                }
                                                {
                                                    open &&
                                                    item.title === "Transaction Log" && (
                                                        (transactionLogOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${isActive ? "primary-color-container" : "text-color-container"}`}
                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${isActive ? "primary-color-container" : "text-color-container"}`}

                                                        />)
                                                    )
                                                }
                                            </Box>
                                        </ListItemButton>
                                        {
                                            item.title === "Entities Data" &&
                                            collapsibleSubMenu(
                                                entityOpen,
                                                entitiesMenu
                                            )
                                        }
                                        {
                                            item.title === "Transaction Data" &&
                                            collapsibleSubMenu(
                                                transactionOpen,
                                                transactionMenu
                                            )
                                        }
                                        {
                                            item.title === "Transaction Log" &&
                                            collapsibleSubMenu(
                                                transactionLogOpen,
                                                transactionLogMenu
                                            )
                                        }
                                    </ListItem>
                                )
                            }
                            return null;
                        })
                    }
                </List>
            </>
        );
    }

    const _renderCarbonXMenu = () => {
        return (
            <>
                <List className='sidebar-list'>
                    {
                        CarbonXMenu.map((item, index) => {
                            let showMenu = validateMenuPermission(item);
                            if (showMenu) {
                                const { icon: IconSvg } = item;
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        className={`sidebar-menu-list`}
                                        sx={{
                                            ...(matchPathName(item.subMenuPaths, location.pathname) && !open && {
                                                borderLeft: "5px solid #E94F1C",
                                            }),
                                        }}
                                    >
                                        <ListItemButton
                                            className={`sidebar-menu-button ${location.pathname === item.childPath && "active-button"}`}
                                            // style={{
                                            //     paddingLeft: "2rem"
                                            // }}
                                            onClick={() => handleDrawerMenuClick(item)}
                                        >
                                            <Box className={matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-class" : "secondary-color-class"}>
                                                <IconSvg className="sidebar-menu-icon" />
                                            </Box>
                                            <ListItemText
                                                primary={item?.title}
                                                className={`sidebar-menu-text ${matchPathName(item.subMenuPaths, location.pathname) ? 'active-text' : ""}`}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                }}
                                            />
                                            <Box>
                                                {
                                                    open &&
                                                    item.title === "Carbon Analytics" && (
                                                        (carbonOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}

                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                        />)
                                                    )
                                                }
                                                {
                                                    open &&
                                                    item.title === "Data Mapping" && (
                                                        (dataMappingOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : isEmptySNOP ? "text-color-container" : "secondary-color-container"}`}
                                                        />)
                                                    )
                                                }
                                            </Box>
                                        </ListItemButton>
                                        {
                                            item.title === "Carbon Analytics" &&
                                            collapsibleSubMenu(
                                                carbonOpen,
                                                carbonAnalyticsMenu
                                            )
                                        }
                                        {
                                            item.title === "Data Mapping" &&
                                            collapsibleSubMenu(
                                                dataMappingOpen,
                                                dataMappingMenu
                                            )
                                        }
                                    </ListItem>
                                )
                            }
                            return null;
                        })
                    }
                </List>
            </>
        );
    }

    const _renderRiskAIMenu = () => {
        return (
            <>
                <List className='sidebar-list'>
                    {
                        RiskAIMenu.map((item, index) => {
                            let showMenu = validateMenuPermission(item);
                            if (showMenu) {
                                const { icon: IconSvg } = item;
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        className={`sidebar-menu-list`}
                                        sx={{
                                            ...(matchPathName(item.subMenuPaths, location.pathname) && !open && {
                                                borderLeft: "5px solid #E94F1C",
                                            }),
                                        }}
                                    >
                                        <ListItemButton
                                            className={`sidebar-menu-button ${location.pathname === item.childPath && "active-button"}`}
                                            onClick={() => handleDrawerMenuClick(item)}
                                        >
                                            <Box className={matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-class" : "secondary-color-class"}>
                                                <IconSvg className="sidebar-menu-icon" />
                                            </Box>
                                            <ListItemText
                                                primary={item?.title}
                                                className={`sidebar-menu-text ${matchPathName(item.subMenuPaths, location.pathname) ? 'active-text' : ""}`}
                                                sx={{
                                                    opacity: open ? 1 : 0,
                                                }}
                                            />
                                            <Box>
                                                {
                                                    open &&
                                                    item.title === "Risk AI" && (
                                                        (riskOpen ? <ExpandLess
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : "secondary-color-container"}`}

                                                        /> : <ExpandMore
                                                            className={`sidebar-menu-icon ${matchPathName(item.subMenuPaths, location.pathname) ? "primary-color-container" : "secondary-color-container"}`}
                                                        />)
                                                    )
                                                }
                                            </Box>
                                        </ListItemButton>
                                        {
                                            item.title === "Risk AI" &&
                                            collapsibleSubMenu(
                                                riskOpen,
                                                riskDtrmMenu
                                            )
                                        }
                                    </ListItem>
                                )
                            }
                            return null;
                        })
                    }
                </List>
            </>
        );
    }

    const _renderMyProfileMenu = () => {
        return (
            <>
                <List className="sidebar-list">
                    <ListItem
                        disablePadding
                        className={`sidebar-menu-list`}
                        sx={{
                            borderLeft: "5px solid #E94F1C",
                        }}
                    >
                        <ListItemButton
                            className="sidebar-menu-button active-button"
                        >
                            <Box>
                                <AccountBoxIcon className="sidebar-menu-icon" />
                            </Box>
                            <ListItemText
                                primary={"My Profile"}
                                className="sidebar-menu-text active-text"
                                sx={{
                                    opacity: open ? 1 : 0,
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </>
        );
    };

    return (
        <>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader className='drawer-header'>
                    <Box className="drawer-icon-container">
                        {
                            logo && !isEmpty(logo) ?
                                <img
                                    src={logo}
                                    alt="logo"
                                    className={`header-logo ${!open && "hidden"}`}
                                />
                                :
                                <LogoSvg />
                        }
                        <IconButton onClick={handleDrawerClose} disableRipple={true}>
                            <img
                                src={HamburgerMenu}
                                alt="logo"
                                className="header-menu-drawer"
                            />
                            {/* )} */}
                        </IconButton>
                    </Box>
                </DrawerHeader>
                <div className='sidebar-container'>
                    {isMyProfile && _renderMyProfileMenu()}
                    {
                        mainMenu === ID &&
                        SYSTEM_ADMIN.some((r) => permissions.includes(r)) &&
                        _renderSystemAdminMenu()
                    }
                    {
                        mainMenu === IBP &&
                        SNOP.some((r) => permissions.includes(r)) &&
                        _renderSNOPMenu()
                    }
                    {
                        mainMenu === IBP &&
                        _renderDPAIMenu()
                    }
                    {
                        mainMenu === EDM &&
                        _renderEDMMenu()
                    }
                    {
                        mainMenu === CX &&
                        _renderCarbonXMenu()
                    }
                    {
                        mainMenu === DTRM &&
                        _renderRiskAIMenu()
                    }
                </div>

                {
                    open && <Box
                        className="copy-right"
                    >
                        <Typography variant="caption" sx={{
                            fontSize: 10
                        }}>
                            Copyright<sup className="copyright-symbol">&copy;</sup> by 3scsolution-{currentYear}-{nextYear}
                        </Typography>
                    </Box>
                }
            </Drawer>
        </>
    )
}

export default Sidebar;
