import React from "react";

export const ReportsIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
      version="1.1"
    >
      <g id="surface1">
        <path
          d="M 13.328125 14.117188 L 2.742188 14.117188 L 2.742188 0.882812 L 9.800781 0.882812 L 9.800781 4.410156 L 13.328125 4.410156 Z M 10.683594 1.066406 L 13.148438 3.53125 L 10.683594 3.53125 Z M 13.824219 2.960938 L 11.253906 0.386719 C 11.003906 0.136719 10.667969 0 10.316406 0 L 1.859375 0 L 1.859375 15 L 14.210938 15 L 14.210938 3.894531 C 14.210938 3.542969 14.074219 3.210938 13.824219 2.960938 Z M 4.507812 9.707031 L 10.683594 9.707031 L 10.683594 8.824219 L 4.507812 8.824219 Z M 4.507812 6.175781 L 11.566406 6.175781 L 11.566406 5.292969 L 4.507812 5.292969 Z M 4.507812 11.46875 L 8.035156 11.46875 L 8.035156 10.589844 L 4.507812 10.589844 Z M 4.507812 7.941406 L 9.800781 7.941406 L 9.800781 7.058594 L 4.507812 7.058594 Z M 4.507812 4.410156 L 8.035156 4.410156 L 8.035156 3.53125 L 4.507812 3.53125 Z M 4.507812 4.410156 "
          fill={fillColor}
        />
      </g>
    </svg>
  );
};
