import React from "react";
export const ProductIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6427 17.2848C19.2085 17.2848 18.8571 17.6363 18.8571 18.0705V20.4276H3.14324V7.07086H18.8571V10.2136C18.8571 10.6479 19.2085 10.9993 19.6427 10.9993C20.077 10.9993 20.4284 10.6479 20.4284 10.2136V7.07086H21.2141C21.574 7.07086 21.8878 6.82604 21.976 6.47702C22.0642 6.12784 21.9039 5.76428 21.5861 5.59309L11.3721 0.0932559C11.1405 -0.0310853 10.8595 -0.0310853 10.6278 0.0932559L0.413863 5.59309C0.0962555 5.76426 -0.0641957 6.12781 0.0239992 6.47702C0.112567 6.82601 0.426313 7.07086 0.786209 7.07086H1.5719V21.2133C1.5719 21.6475 1.92335 21.999 2.35759 21.999H19.6428C20.077 21.999 20.4285 21.6475 20.4285 21.2133V18.0705C20.4285 17.6363 20.077 17.2848 19.6428 17.2848H19.6427ZM11.0002 1.67788L18.0974 5.49971H3.9029L11.0002 1.67788Z"
        fill={fillColor}
      />
      <path
        d="M9.77999 7.9398C9.55901 7.82931 9.29807 7.82931 9.07709 7.9398L4.36295 10.2969C4.09672 10.4303 3.92871 10.702 3.92871 10.9996V16.4994C3.92871 16.7972 4.09672 17.0687 4.36295 17.2023L9.07709 19.5594C9.18758 19.6146 9.30806 19.6422 9.42855 19.6422C9.54903 19.6422 9.66951 19.6145 9.78 19.5592L14.4941 17.2022C14.7604 17.0687 14.9284 16.7972 14.9284 16.4994V10.9996C14.9284 10.7018 14.7604 10.4303 14.4941 10.2967L9.77999 7.9398ZM9.42854 9.52101L12.3856 10.9996L9.42854 12.4783L6.47151 10.9996L9.42854 9.52101ZM5.50008 12.2709L8.64285 13.8423V17.585L5.50008 16.0136V12.2709ZM10.2142 17.5853V13.8426L13.357 12.2712V16.014L10.2142 17.5853Z"
        fill={fillColor}
      />
      <path
        d="M21.2146 13.3566H18.3971L18.6274 13.1264C18.9343 12.8194 18.9343 12.3223 18.6274 12.0153C18.3205 11.7084 17.8233 11.7084 17.5164 12.0153L15.945 13.5867C15.6381 13.8936 15.6381 14.3908 15.945 14.6977L17.5164 16.2691C17.6699 16.4227 17.8708 16.4994 18.0718 16.4994C18.2728 16.4994 18.474 16.4226 18.6274 16.2691C18.9343 15.9622 18.9343 15.465 18.6274 15.1581L18.3971 14.928H21.2146C21.6488 14.928 22.0003 14.5766 22.0003 14.1423C22.0003 13.7081 21.6488 13.3566 21.2146 13.3566V13.3566Z"
        fill={fillColor}
      />
    </svg>
  );
};
