import React from "react";
export const RmnodemappingIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
      <path d="M25.1,11.5l-0.2-0.1c-0.2-0.1-4.5-2.1-4.5-6.6c0-2.6,2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8c0,4.5-4.4,6.5-4.5,6.6
		L25.1,11.5z M25.1,1.3c-2,0-3.6,1.6-3.6,3.5c0,3.1,2.7,4.9,3.6,5.4c0.8-0.5,3.6-2.2,3.6-5.4C28.7,2.8,27.1,1.3,25.1,1.3z"/>
	<path d="M25.1,7.2c-1.3,0-2.4-1.1-2.3-2.4c0-1.3,1.1-2.4,2.4-2.3c1.3,0,2.3,1.1,2.3,2.4C27.5,6.1,26.4,7.2,25.1,7.2z
		 M25.1,3.7c-0.6,0-1.1,0.5-1.1,1.2S24.5,6,25.2,6s1.1-0.5,1.1-1.2C26.3,4.2,25.7,3.7,25.1,3.7z"/>
	<path d="M0.6,29.9c-0.1,0-0.3,0-0.4-0.1C0.1,29.7,0,29.4,0,29.2l1.2-4l-1.2-4c-0.1-0.3,0.1-0.6,0.4-0.7
		c0.1,0,0.3,0,0.4,0l7.7,4.2c0.3,0.1,0.4,0.5,0.2,0.8c0,0.1-0.1,0.2-0.2,0.2l-7.7,4.2C0.8,29.9,0.7,29.9,0.6,29.9z M1.6,22.2
		l0.8,2.8c0,0.1,0,0.2,0,0.3l-0.8,2.8l5.5-3L1.6,22.2z"/>
	<path d="M25.1,12.6H10.8c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h14.3c0.3,0,0.6,0.2,0.6,0.6S25.4,12.6,25.1,12.6z"/>
	<path d="M24.2,25.7h-6.9c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h6.9c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7h-0.3
		c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h0.3c2.2,0,3.9,1.7,3.9,3.9S26.4,25.7,24.2,25.7z"/>
	<path d="M13.8,25.7h-3.6c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h3.6c0.3,0,0.6,0.2,0.6,0.6S14.1,25.7,13.8,25.7z"/>
	<path d="M20.3,19.2H5.7c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9h1.5c0.3,0,0.6,0.2,0.6,0.6s-0.2,0.6-0.6,0.6H5.7
		c-1.5,0-2.7,1.2-2.7,2.7S4.2,18,5.7,18h14.7c0.3,0,0.6,0.2,0.6,0.6C20.9,18.9,20.6,19.2,20.3,19.2z"/>
	<path d="M9,14.4c-1.3,0-2.4-1.1-2.4-2.4S7.7,9.6,9,9.6s2.4,1.1,2.4,2.4S10.3,14.4,9,14.4z M9,10.8
		c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2S9.7,10.8,9,10.8z"/>
	<path d="M22.1,21c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4C24.5,19.9,23.4,21,22.1,21z
		 M22.1,17.4c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2C23.3,17.9,22.8,17.4,22.1,17.4z"/>
	<path d="M15.5,27.5c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4S16.9,27.5,15.5,27.5z M15.5,24
		c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2S16.2,24,15.5,24z"/>
      </g>
    </svg>
  );
};
