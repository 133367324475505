import React from "react";
export const SalesOrderIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
      <path d="M48.1 61.9C47.5 61.9 47.1 62.3 47.1 62.9V64H2V12.5H12.2C11.9 13.6 11.7 14.8 11.7 16.3C11.7 16.9 12.1 17.3 12.7 17.3H36.5C37.1 17.3 37.5 16.9 37.5 16.3C37.5 14.9 37.3 13.7 37 12.5H47.2V19.4C47.2 20 47.6 20.4 48.2 20.4C48.8 20.4 49.2 20 49.2 19.4V11.5C49.2 10.9 48.8 10.5 48.2 10.5H36.2C35.2 8.7 33.7 7.3 31.6 6.3C31.4 2.6 28.2 0 24.6 0C21 0 17.8 2.7 17.6 6.3C15.9 7.1 14.2 8.3 13 10.5H1C0.4 10.5 0 10.9 0 11.5V65C0 65.6 0.4 66 1 66H48.1C48.7 66 49.1 65.6 49.1 65V62.9C49.1 62.4 48.6 61.9 48.1 61.9ZM18.9 7.9C19.3 7.7 19.6 7.3 19.5 6.9C19.3 4.1 21.7 1.9 24.5 1.9C27.3 1.9 29.6 4 29.5 6.9C29.5 7.3 29.7 7.7 30.1 7.9C33.3 9.2 35.1 11.6 35.3 15.3H13.7C14 11.7 15.7 9.2 18.9 7.9Z" fill="#1D1D1B"/>
<path d="M24.5001 11.7004C26.6001 11.7004 28.4001 10.0004 28.4001 7.80039C28.4001 5.70039 26.7001 3.90039 24.5001 3.90039C22.4001 3.90039 20.6001 5.60039 20.6001 7.80039C20.7001 9.90039 22.4001 11.7004 24.5001 11.7004ZM24.5001 5.90039C25.5001 5.90039 26.4001 6.70039 26.4001 7.80039C26.4001 8.90039 25.6001 9.70039 24.5001 9.70039C23.5001 9.70039 22.6001 8.90039 22.6001 7.80039C22.6001 6.70039 23.5001 5.90039 24.5001 5.90039Z" fill="#1D1D1B"/>
<path d="M14.6999 23.2998C14.6999 22.6998 14.2999 22.2998 13.6999 22.2998H6.8999C6.2999 22.2998 5.8999 22.6998 5.8999 23.2998V30.5998C5.8999 31.1998 6.2999 31.5998 6.8999 31.5998H13.6999C14.2999 31.5998 14.6999 31.1998 14.6999 30.5998V23.2998ZM12.6999 29.4998H7.8999V24.1998H12.6999V29.4998Z" fill="#1D1D1B"/>
<path d="M14.6999 36.7002C14.6999 36.1002 14.2999 35.7002 13.6999 35.7002H6.8999C6.2999 35.7002 5.8999 36.1002 5.8999 36.7002V44.0002C5.8999 44.6002 6.2999 45.0002 6.8999 45.0002H13.6999C14.2999 45.0002 14.6999 44.6002 14.6999 44.0002V36.7002ZM12.6999 43.0002H7.8999V37.7002H12.6999V43.0002Z" fill="#1D1D1B"/>
<path d="M13.6999 49.0996H6.8999C6.2999 49.0996 5.8999 49.4996 5.8999 50.0996V57.3996C5.8999 57.9996 6.2999 58.3996 6.8999 58.3996H13.6999C14.2999 58.3996 14.6999 57.9996 14.6999 57.3996V50.0996C14.6999 49.4996 14.1999 49.0996 13.6999 49.0996ZM12.6999 56.3996H7.8999V51.0996H12.6999V56.3996Z" fill="#1D1D1B"/>
<path d="M65.3998 32.0002L47.2998 22.1002C46.9998 21.9002 46.5998 21.9002 46.3998 22.1002C42.9998 23.9002 31.6998 29.9002 28.2998 31.8002C27.9998 32.0002 27.7998 32.3002 27.7998 32.7002V43.0002C22.4998 43.1002 18.2998 47.4002 18.2998 52.7002C18.2998 58.0002 22.5998 62.4002 27.9998 62.4002C32.4998 62.4002 36.2998 59.3002 37.2998 55.2002L46.3998 61.0002C46.6998 61.2002 46.9998 61.2002 47.3998 61.0002L65.5998 51.3002C65.8998 51.1002 66.0998 50.8002 66.0998 50.4002V32.9002C65.9998 32.5002 65.6998 32.1002 65.3998 32.0002ZM46.7998 24.1002L62.9998 32.9002L58.7998 35.2002L42.5998 26.3002L46.7998 24.1002ZM54.7998 37.9002L37.9998 28.8002L40.3998 27.5002L57.6998 37.0002V41.9002L55.2998 43.2002V38.8002C55.2998 38.4002 55.0998 38.0002 54.7998 37.9002ZM35.8998 29.9002L52.1998 38.8002L46.7998 41.8002L30.6998 32.7002L35.8998 29.9002ZM27.7998 60.3002C23.5998 60.3002 20.0998 56.9002 20.0998 52.6002C20.0998 48.3002 23.4998 44.9002 27.7998 44.9002C32.0998 44.9002 35.4998 48.3002 35.4998 52.6002C35.4998 56.9002 31.9998 60.3002 27.7998 60.3002ZM37.3998 52.9002C37.4998 48.0002 34.0998 44.0002 29.5998 43.2002V34.3002L45.6998 43.4002V58.1002L37.3998 52.9002ZM47.7998 58.3002V43.5002L53.2998 40.5002V44.9002C53.2998 45.3002 53.4998 45.6002 53.7998 45.8002C53.9998 45.9002 54.0998 45.9002 54.2998 45.9002C54.4998 45.9002 54.5998 45.9002 54.7998 45.8002L59.1998 43.4002C59.4998 43.2002 59.6998 42.9002 59.6998 42.5002V37.0002L63.9998 34.7002V49.8002L47.7998 58.3002Z" fill="#1D1D1B"/>
<path d="M32.8 26.9004C32.8 26.3004 32.4 25.9004 31.8 25.9004H17.5C16.9 25.9004 16.5 26.3004 16.5 26.9004C16.5 27.5004 16.9 27.9004 17.5 27.9004H31.8C32.3 27.9004 32.8 27.5004 32.8 26.9004Z" fill="#1D1D1B"/>
<path d="M17.3999 39.4004C16.7999 39.4004 16.3999 39.8004 16.3999 40.4004C16.3999 41.0004 16.7999 41.4004 17.3999 41.4004H25.6999C26.2999 41.4004 26.6999 41.0004 26.6999 40.4004C26.6999 39.8004 26.2999 39.4004 25.6999 39.4004H17.3999Z" fill="#1D1D1B"/>
<path d="M33.2998 49.1C32.8998 48.7 32.2998 48.7 31.8998 49L25.6998 54.7L23.6998 51.9C23.3998 51.4 22.7998 51.3 22.2998 51.7C21.7998 52 21.6998 52.6 22.0998 53.1L24.6998 56.9C24.8998 57.1 25.0998 57.3 25.3998 57.3H25.4998C25.6998 57.3 25.9998 57.2 26.1998 57L33.1998 50.5C33.6998 50.2 33.6998 49.5 33.2998 49.1Z" fill="#1D1D1B"/>
      </g>
    </svg>
  );
};
