import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "font-awesome/css/font-awesome.min.css";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import Logo1 from "../../assets/3SC-logo.svg";
import InputField from "../../globalComponent/InputField";
import { setPassword } from "../../utils/ApiHandler";
import "./set-new-password.scss";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, SetFormData] = useState({
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    otp: "",
  });

  const parsed = queryString.parse(location.search);

  const [check, setCheckError] = useState({
    passwordValid: false,
    passwordMatching: false,
  });

  const {
    newPassword,
    confirmPassword,
    showConfirmPassword,
    showPassword,
    // otp,
  } = formData;

  const onChange = (e) =>
    SetFormData({ ...formData, [e.target.name]: e.target.value });

  const handleClickShowPassword = () => {
    SetFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    SetFormData({
      ...formData,
      showConfirmPassword: !formData.showConfirmPassword,
    });
  };

  const checkPassword = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setCheckError({ ...check, passwordValid: true });
    } else {
      setCheckError({ ...check, passwordValid: false });
    }
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setCheckError({ ...check, passwordMatching: false });
    } else {
      newPassword !== "" &&
        confirmPassword !== "" &&
        setCheckError({ ...check, passwordMatching: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, confirmPassword]);

  const validPassword = check.passwordMatching && check.passwordValid;

  const onSubmit = async (password, token) => {
    const res = await setPassword(password, token);
    if (res.status === 200) {
      toast.success("Password creation successfull !!!");
      navigate(`/`);
    }
  };

  return (
    <div className="set-new-password-container">
      <div className="set-new-passsword">
        <div className="logo-container">
          <div className="logo-container-logo">
            <img src={Logo1} alt="logo" className="logo-img" />
          </div>
        </div>
        <div className="set-new-passsword-form">
          <h1 className="set-new-passsword-heading">{"Change Password"}</h1>
          <span className="set-new-passsword-subheading">
            {"Your new password must be different to"} <br />
            {"previously used passwords."}
          </span>

          <div className="set-new-passsword-input">
            <InputField
              label={"New Password"}
              variant="outlined"
              name="newPassword"
              value={newPassword}
              onChange={(e) => {
                onChange(e);
                checkPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              icon={[
                <>
                  {showPassword ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={handleClickShowPassword}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      onClick={handleClickShowPassword}
                    ></i>
                  )}
                </>,
              ]}
            />

            {check.passwordValid ? (
              <>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-${"item"}`}>
                      Password is valid
                    </Tooltip>
                  }
                >
                  <CheckCircleIcon className="input-check-icon" />
                </OverlayTrigger>
              </>
            ) : (
              <>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-${"item"}`}>
                      *Must be at least 8 characters. <br />
                      *Must contain atleast one upper case letter and one lower
                      case letter. <br /> *Must contain atleast a number and a
                      special character.
                    </Tooltip>
                  }
                >
                  <CheckCircleIcon className="input-check-icon input-check-icon-disabled" />
                </OverlayTrigger>
              </>
            )}

            <span className="set-new-passsword-disclaimer">
              {"*Must be at least 8 characters."} <br />
              {
                "*Must contain atleast one upper case letter and one lower case letter."
              }
              <br />
              {"*Must contain atleast a number and a special character."}
            </span>
          </div>
          <div className="set-new-passsword-input">
            <InputField
              label={"Confirm Password"}
              className="set-new-passsword-input"
              variant="outlined"
              name="confirmPassword"
              value={confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              onChange={(e) => {
                onChange(e);
              }}
              icon={[
                <>
                  {showPassword ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={handleClickShowConfirmPassword}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      onClick={handleClickShowConfirmPassword}
                    ></i>
                  )}
                </>,
              ]}
            />
            {check.passwordMatching ? (
              <>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-${"item"}`}>
                      New Password and Confirm password matches
                    </Tooltip>
                  }
                >
                  <CheckCircleIcon className="input-check-icon" />
                </OverlayTrigger>
              </>
            ) : (
              <>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-${"item"}`}>
                      New Password and Confirm password should match.
                    </Tooltip>
                  }
                >
                  <CheckCircleIcon className="input-check-icon input-check-icon-disabled" />
                </OverlayTrigger>
              </>
            )}
          </div>
          <button
            className={`set-new-passsword-button ${!validPassword && "set-new-passsword-button-disabled"
              }`}
            onClick={() =>
              validPassword && onSubmit(formData.newPassword, parsed.token)
            }
          >
            {"Set Password"}
          </button>
          <Link to="/" className="remove-underline">
            <div className="set-new-passsword-back">
              <KeyboardBackspaceIcon />
              <span>{"Back to Login"}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
