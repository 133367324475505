import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
function Network() {
    const {t} = useTranslation();
    const [status, setStatus] = useState(() => {
        if (navigator.onLine) {
            return true;
        } else {
            return false;
        }
    });
    const lastStatus = useRef(null);
    useEffect(() => {
        window.ononline = () => {
            setStatus(true);
        };
        window.onoffline = () => {
            setStatus(false);
        };
    }, [status]);

    useEffect(() => {
        lastStatus.current = status;
    }, [status])

    return (
        <>
            {status && lastStatus.current === false && toast.success(t("online"), {
                toastId: 2
            })}
            {
                !status && toast.error(t("offline"), {
                    toastId: 1
                })
            }
        </>
    );
}
export default Network;