import Cookies from 'js-cookie';
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout, tokenRefresh, tokenVerify } from "../utils/ApiHandler";
import {
  additionalRoutes,
  carbonAnalyticsMenu,
  dataMappingMenu,
  demandMenu,
  entitiesMenu,
  inventoryMenu,
  productMenu,
  productionMenu,
  rawMaterialMenu,
  riskDtrmMenu,
  sideBarMenu,
  snopMenu,
  supply_operationMenu,
  supply_reportMenu,
  transactionLogMenu,
  transactionMenu
} from "../utils/Constants";
import Loader from "./../components/Loader/index";

const PrivateRoute = ({ children, path }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const _userCookies = JSON.parse(Cookies.get("user") || null);
  const user = JSON.parse(localStorage.getItem("user") || null);
  const accessToken = Cookies.get("token");
  const refreshToken = JSON.parse(localStorage.getItem("refresh"));
  const [isTokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validRoute, setValidRoute] = useState(false);
  const permissions = get(user, "permissions", []);

  const menuPaths = [
    ...sideBarMenu,
    ...demandMenu,
    ...entitiesMenu,
    ...transactionMenu,
    ...transactionLogMenu,
    ...snopMenu,
    ...additionalRoutes,
    ...carbonAnalyticsMenu,
    ...dataMappingMenu,
    ...inventoryMenu,
    ...productionMenu,
    ...rawMaterialMenu,
    ...supply_reportMenu,
    ...supply_operationMenu,
    ...riskDtrmMenu,
    ...productMenu
  ];

  const _pathAccess = () => {
    const menu = menuPaths.find((el) => el.childPath === path);
    if (menu.permission.some((r) => permissions.includes(r))) {
      setValidRoute(true);
    }
  };

  const _refreshAccessToken = async () => {
    try {
      if (!_userCookies)
        throw new Error("Sorry, your token has expired.");
      const authenticator = localStorage.getItem('authenticator');
      const res = await tokenRefresh(refreshToken, authenticator);
      if (res.status === 200) {
        const _token = JSON.stringify(res.data.access);
        Cookies.set("token", _token, { expires: 1 });
        setTokenValid(true);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (
        typeof error?.response?.data === "undefined" ||
        typeof error?.response?.data?.detail === "undefined"
      ) {
        toast.error(error?.message);
      }
      toast.error(error?.response?.data?.detail || error);
      setLoading(false);
      setTokenValid(false);
      logout();
      navigate("/");
    }
  };

  const _verifyToken = async () => {
    try {
      const res = await tokenVerify(JSON.parse(accessToken));
      if (res.status === 200) {
        setTokenValid(true);
        setLoading(false);
      }
    } catch (error) {
      _refreshAccessToken();
      console.error(error);
      setLoading(true);
    }
  };

  useEffect(() => {
    _verifyToken();
    _pathAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenValid]);

  if (loading) {
    return <Loader />;
  }

  if (validRoute) {
    if (isTokenValid) {
      if (path.includes("/:")) {
        const _path = location.pathname;
        localStorage.setItem("childRoute", _path);
      } else {
        localStorage.setItem("childRoute", path);
      }
      return children;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return <Navigate to="/error" />;
  }
};

export default PrivateRoute;
