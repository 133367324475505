import React from "react";
export const ForecastIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6308 16.4155L18.6904 13.6602C19.5471 12.5257 20.0564 11.1133 20.0564 9.58532C20.0564 8.33513 19.7092 7.17747 19.1304 6.15869L17.7875 7.50158C18.0886 8.12675 18.2505 8.84446 18.2505 9.58528C18.2505 11.8543 16.7457 13.7529 14.685 14.378C14.9166 14.9799 14.9166 15.6515 14.6387 16.2534C15.6342 16.045 16.5373 15.6282 17.3245 15.0262L20.0565 17.9899C20.0796 18.0129 20.1028 18.0362 20.1259 18.0593C20.5889 18.476 21.3067 18.4529 21.7235 17.9899C22.1171 17.5499 22.0939 16.832 21.6308 16.4154L21.6308 16.4155Z"
        fill={fillColor}
      />
      <path
        d="M6.58096 9.77059C7.15979 9.77059 7.71551 9.84003 8.24799 9.95583C8.24799 9.84003 8.2249 9.72424 8.2249 9.58535C8.2249 6.83007 10.4708 4.58425 13.226 4.58425H13.3881L14.9625 3.00992C14.4068 2.87102 13.8279 2.77832 13.226 2.77832C9.47521 2.77832 6.41895 5.83463 6.41895 9.58536V9.77061H6.58094L6.58096 9.77059Z"
        fill={fillColor}
      />
      <path
        d="M15.3793 9.84015C15.3562 9.60855 15.2635 9.37713 15.0782 9.21498L14.3605 8.56669L19.8478 3.07938V4.3065C19.8478 4.7928 20.2414 5.20947 20.7508 5.20947C21.2371 5.20947 21.6538 4.81589 21.6538 4.3065L21.654 0.902975C21.654 0.416675 21.2604 0 20.751 0H17.3242C16.8379 0 16.4212 0.393584 16.4212 0.902975C16.4212 1.41235 16.8148 1.80595 17.3242 1.80595H18.5513L12.4157 7.9647C12.2305 8.14995 12.1379 8.38138 12.161 8.63606C12.1841 8.89075 12.2768 9.12236 12.4621 9.28435L13.203 9.95571L11.7676 11.507C12.3233 11.8775 12.8094 12.271 13.203 12.6183L15.1711 10.4882C15.3099 10.3262 15.4024 10.0715 15.3793 9.84007L15.3793 9.84015Z"
        fill={fillColor}
      />
      <path
        d="M6.5813 11.4609C3.06214 11.4609 0.306786 14.6329 0.19102 14.7718C-0.0636734 15.0496 -0.0636734 15.4895 0.19102 15.7673C0.306819 15.9062 3.06206 19.0781 6.5813 19.0781C10.1006 19.0783 12.8558 15.9063 12.9716 15.7674C13.2263 15.4896 13.2263 15.0497 12.9716 14.7719C12.8558 14.6329 10.1006 11.4609 6.5813 11.4609ZM6.5813 17.9207C5.12261 17.9207 3.91877 16.7399 3.91877 15.2581C3.91877 13.7995 5.09954 12.5956 6.5813 12.5956C8.04 12.5956 9.24384 13.7764 9.24384 15.2581C9.244 16.74 8.04 17.9207 6.5813 17.9207Z"
        fill={fillColor}
      />
      <path
        d="M6.58084 13.6607C6.41869 13.6607 6.2798 13.6837 6.14089 13.7301C6.23343 13.869 6.30304 14.0311 6.30304 14.1931C6.30304 14.6331 5.9558 14.9803 5.51587 14.9803C5.33062 14.9803 5.19173 14.9108 5.05285 14.8181C5.00649 14.957 4.9834 15.0959 4.9834 15.2581C4.9834 16.138 5.70111 16.8557 6.58101 16.8557C7.46092 16.8557 8.17862 16.138 8.17862 15.2581C8.17846 14.3784 7.46075 13.6606 6.58085 13.6606L6.58084 13.6607Z"
        fill={fillColor}
      />
    </svg>
  );
};
