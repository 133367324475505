import { createBrowserHistory } from "history";
import React from "react";
import MicroFrontend from "../../MicroFrontend";
import Layout from "../../components/Layouts/Layout";
import "../style.scss";
const defaultHistory = createBrowserHistory();

const RMFrontend = () => {
    const { REACT_APP_RM_HOST: rmHost } = process.env;

    function RMModule({ history }) {
        return <MicroFrontend history={history} host={rmHost} name="riskai" />;
    }

    return (
        <Layout>
            <div id="riskai">
                <div>
                    <RMModule history={defaultHistory} />
                </div>
            </div>
        </Layout>
    );
};

export default RMFrontend;
