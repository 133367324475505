import React from "react";
export const CurrencyIcon = () => {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 100 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.2,70c0-2.8,2.1-5.2,4.8-5.7v-1.6c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4v1.7c2.5,0.6,4.4,2.9,4.4,5.6
	c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-1.6-1.3-3-3-3h-0.4c-1.6,0-3,1.3-3,3s1.3,3,3,3h0.8c3.2,0,5.8,2.6,5.8,5.7
	c0,2.8-2.1,5.2-4.8,5.7V86c0,0.8-0.6,1.4-1.4,1.4S39,86.8,39,86v-1.7c-2.5-0.6-4.4-2.9-4.4-5.6c0-0.8,0.6-1.4,1.4-1.4
	s1.4,0.6,1.4,1.4c0,1.6,1.3,3,3,3h0.4c1.6,0,3-1.3,3-3c0-1.6-1.3-3-3-3h-0.8C36.8,75.7,34.2,73.2,34.2,70z M16.7,75.7H24
	c0.8,0,1.4-0.6,1.4-1.4S24.8,73,24,73h-7.3c-0.8,0-1.4,0.6-1.4,1.4S15.9,75.7,16.7,75.7z M95.9,69.2c0,1.2-0.5,2.3-1.3,3.2
	c0.8,0.8,1.3,1.9,1.3,3.2v4.9c0,1.2-0.5,2.3-1.3,3.2c0.8,0.8,1.3,1.9,1.3,3.2v4.9c0,2.5-2,4.5-4.5,4.5H6.1c-2.2,0-4-1.8-4-4V56.5
	c0-0.4,0.1-0.7,0.1-1.1c-0.1-1.1-0.1-2.2-0.1-3.4c0-11.9,6.1-23.9,15.2-30.5c-0.1-0.3-0.2-0.7-0.2-1.1v-1.5c0-1.2,0.7-2.2,1.6-2.7
	c-4-4.6-5.4-9.2-3.4-11.3c1-1,3.3-2.2,8.3,1.6c1.3-3,3.4-4.8,5.7-4.8s4.4,1.8,5.7,4.8c5-3.8,7.3-2.7,8.3-1.6c2,2.1,0.6,6.8-3.4,11.3
	c1,0.5,1.6,1.5,1.6,2.7v1.5c0,0.4-0.1,0.7-0.2,1.1c5.7,4.2,10.5,11,13.1,18.4c3-8.2,10.8-14.1,20.1-14.1c11.7,0,21.3,9.6,21.3,21.3
	c0,4.7-1.5,9.1-4.1,12.6c2.3,0.2,4.1,2.2,4.1,4.5L95.9,69.2L95.9,69.2z M91.4,62.5h-2c-3.8,3.7-9,6-14.8,6s-10.9-2.3-14.8-6h-2
	c-1,0-1.8,0.8-1.8,1.8v4.9c0,1,0.8,1.8,1.8,1.8h33.5c1,0,1.8-0.8,1.8-1.8v-4.9C93.2,63.3,92.4,62.5,91.4,62.5z M91.4,73.7H57.9
	c-1,0-1.8,0.8-1.8,1.8v4.9c0,1,0.8,1.8,1.8,1.8h33.5c1,0,1.8-0.8,1.8-1.8v-4.9C93.2,74.5,92.4,73.7,91.4,73.7z M4.8,56.5v6.6
	c4-0.6,7.2-3.8,7.8-7.8H6.1C5.4,55.3,4.8,55.8,4.8,56.5z M4.8,82.8c5.6,0.6,10,5.1,10.6,10.6h38.2c-0.2-0.5-0.4-1.1-0.4-1.8v-4.9
	c0-1.2,0.5-2.3,1.3-3.2c-0.8-0.8-1.3-1.9-1.3-3.2v-4.9c0-1.2,0.5-2.3,1.3-3.2c-0.8-0.8-1.3-1.9-1.3-3.2v-4.9c0-2.4,1.8-4.3,4.1-4.5
	c-1-1.4-1.9-2.9-2.5-4.5H15.5c-0.6,5.6-5.1,10-10.6,10.6L4.8,82.8L4.8,82.8z M56.1,47.1c0,10.2,8.3,18.5,18.5,18.5
	s18.5-8.3,18.5-18.5c0-10.2-8.3-18.5-18.5-18.5S56.1,36.9,56.1,47.1z M20.2,20.7h18.5c0.1,0,0.3-0.1,0.3-0.3v-1.5
	c0-0.1-0.1-0.3-0.3-0.3H20.2c-0.1,0-0.3,0.1-0.3,0.3v1.5C19.9,20.6,20,20.7,20.2,20.7z M21.1,14.7c0.4,0.4,0.7,0.8,1.1,1.2h14.4
	c0.4-0.4,0.8-0.8,1.1-1.2c3.8-4.2,4.2-7.3,3.7-7.9c-0.2-0.2-1.6-0.4-4.9,2.2c-0.6,0.5-1.4,0.6-2.2,0.4C33.5,9.2,33,8.7,32.7,8
	c-0.8-2.1-2.1-3.4-3.3-3.4c-1.2,0-2.5,1.3-3.3,3.4c-0.3,0.7-0.8,1.2-1.6,1.4c-0.8,0.2-1.6,0.1-2.2-0.4c-3.3-2.6-4.7-2.4-4.9-2.2
	C16.9,7.4,17.3,10.5,21.1,14.7z M4.8,52.1c0,0.2,0,0.4,0,0.6c0.4-0.1,0.8-0.2,1.2-0.2h26.2c-0.6-0.6-1.5-0.9-2.4-0.9h-0.9
	c-3.5,0-6.4-2.9-6.4-6.4c0-3.2,2.4-5.9,5.5-6.4v-2.1c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4v2.2c2.9,0.6,5,3.2,5,6.3
	c0,0.8-0.6,1.4-1.4,1.4S33,45.9,33,45.2c0-2-1.6-3.7-3.7-3.7h-0.5c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7h0.9c2.5,0,4.8,1.5,5.8,3.7
	H54c-0.4-1.7-0.7-3.5-0.7-5.4c0-0.4,0-0.8,0-1.2c-1.7-9-7.2-17.8-14-22.6c-0.2,0.1-0.5,0.1-0.7,0.1H20.2c-0.2,0-0.5,0-0.7-0.1
	C10.7,29.4,4.8,40.8,4.8,52.1z M6.1,93.4h6.6c-0.6-4-3.8-7.2-7.8-7.8v6.6C4.8,92.9,5.4,93.4,6.1,93.4z M93.2,86.7
	c0-1-0.8-1.8-1.8-1.8H57.9c-1,0-1.8,0.8-1.8,1.8v4.9c0,1,0.8,1.8,1.8,1.8h33.5c1,0,1.8-0.8,1.8-1.8V86.7z M59.3,47.1
	c0-8.5,6.9-15.4,15.4-15.4S90,38.7,90,47.1s-6.9,15.4-15.3,15.4C66.2,62.5,59.3,55.6,59.3,47.1z M62.1,47.1
	c0,6.9,5.6,12.6,12.6,12.6s12.6-5.6,12.6-12.6s-5.6-12.6-12.6-12.6C67.7,34.6,62.1,40.2,62.1,47.1z M74.6,48.5c1.1,0,2.3,0.5,2.3,2
	c0,1.5-1.2,2-2.3,2c-1.1,0-2-0.9-2-2c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4c0,2.1,1.4,4,3.4,4.6v1.1c0,0.8,0.6,1.4,1.4,1.4
	S76,57,76,56.2v-1.1c2.2-0.5,3.7-2.3,3.7-4.6c0-2.8-2.1-4.8-5.1-4.8c-1.1,0-2.3-0.5-2.3-2s1.2-2,2.3-2c1.1,0,2,0.9,2,2
	c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-2.1-1.4-4-3.4-4.6v-1.1c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4v1.1
	c-2.2,0.5-3.7,2.3-3.7,4.6C69.6,46.5,71.7,48.5,74.6,48.5z"
			/>
		</svg>
	);
};
