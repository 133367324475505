import axios from "axios";
import Cookies from 'js-cookie';
import { apiBaseUrl, apiIpPort } from "./ApiHandler";
const token = JSON.parse(Cookies.get("token") || null);

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const getUserAPI = async ({ orgId, userId }) => {
  return await axios.get(
    `${apiIpPort}${apiBaseUrl}/org/${orgId}/users/${userId}`,
    config
  );
};

export const editUserAPI = async ({ orgId, userId, payload }) => {
  return await axios.put(
    `${apiIpPort}${apiBaseUrl}/org/${orgId}/users/${userId}`,
    payload,
    config
  );
};
