import Cookies from 'js-cookie';
import { get } from "lodash";

export const user = JSON.parse(Cookies.get("user") || null);
export const user_metadata = JSON.parse(localStorage.getItem("user") || null);
const snop = JSON.parse(localStorage.getItem("snop") || null);
export const selected_business_unit = JSON.parse(localStorage.getItem("selected_business_unit") || null);

export const buId = selected_business_unit?.business_unit_id || null;
export const tenantId = user_metadata?.tenant_id || null;
export const snopId = snop?.snop_id || null;
export const firstName = get(user, "first_name", "");
export const lastName = get(user, "last_name", "");
export const businessUnitName = selected_business_unit?.business_unit_name;
export const userRole = get(user_metadata, "roles", []);
export const business_units = get(user_metadata, "business_units") || [];
export const userId = user?.user_id || null;
