import comLogo from "../assets/com-log.svg";
import backIcon from "../assets/back_icon.svg";
import lockHide from "../assets/lock_hide.svg";
import lockUnhide from "../assets/lock_unhide.svg";

const images = {
    comLogo,
    backIcon,
    lockHide,
    lockUnhide,
};

export default images;