import React from "react";
export const DataMappingIcon = ({ color }) => {
  return (
    <svg
      width="22"
      height="10"
      viewBox="0 0 22 10"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7C0 6.46957 0.237053 5.96086 0.65901 5.58579C1.08097 5.21071 1.65326 5 2.25 5H15.75C16.3467 5 16.919 5.21071 17.341 5.58579C17.7629 5.96086 18 6.46957 18 7V8C18 8.53043 17.7629 9.03914 17.341 9.41421C16.919 9.78929 16.3467 10 15.75 10H2.25C1.65326 10 1.08097 9.78929 0.65901 9.41421C0.237053 9.03914 0 8.53043 0 8V7ZM2.8125 8C2.96168 8 3.10476 7.94732 3.21025 7.85355C3.31574 7.75979 3.375 7.63261 3.375 7.5C3.375 7.36739 3.31574 7.24021 3.21025 7.14645C3.10476 7.05268 2.96168 7 2.8125 7C2.66332 7 2.52024 7.05268 2.41475 7.14645C2.30926 7.24021 2.25 7.36739 2.25 7.5C2.25 7.63261 2.30926 7.75979 2.41475 7.85355C2.52024 7.94732 2.66332 8 2.8125 8ZM5.0625 8C5.21168 8 5.35476 7.94732 5.46025 7.85355C5.56574 7.75979 5.625 7.63261 5.625 7.5C5.625 7.36739 5.56574 7.24021 5.46025 7.14645C5.35476 7.05268 5.21168 7 5.0625 7C4.91332 7 4.77024 7.05268 4.66475 7.14645C4.55926 7.24021 4.5 7.36739 4.5 7.5C4.5 7.63261 4.55926 7.75979 4.66475 7.85355C4.77024 7.94732 4.91332 8 5.0625 8ZM1.02375 4.204C1.41464 4.0687 1.83052 3.99951 2.25 4H15.75C16.182 4 16.596 4.072 16.9763 4.204L14.8759 0.782C14.7307 0.545353 14.5165 0.347841 14.2558 0.210231C13.9951 0.0726216 13.6975 -8.8683e-07 13.3943 0H4.60575C4.30253 -8.8683e-07 4.00491 0.0726216 3.74418 0.210231C3.48346 0.347841 3.26927 0.545353 3.12412 0.782L1.02375 4.204Z"
        fill={color}
      />
    </svg>
  );
};
