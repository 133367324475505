import React from "react";
export const TransactionDataIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 22.0001H0.833333C0.372767 22.0001 0 21.6483 0 21.2144V5.50007C0 5.06617 0.372767 4.71436 0.833333 4.71436H14.1667C14.6272 4.71436 15 5.06617 15 5.50007V21.2144C15 21.6483 14.6272 22.0001 14.1667 22.0001ZM1.66667 20.4286H13.3333V6.28578H1.66667V20.4286Z"
        fill={fillColor}
      />
      <path
        d="M16.6667 19.6426H15C14.5394 19.6426 14.1667 19.2908 14.1667 18.8569C14.1667 18.423 14.5394 18.0712 15 18.0712H15.8333V3.92836H4.16667V4.71408C4.16667 5.14797 3.7939 5.49979 3.33333 5.49979C2.87277 5.49979 2.5 5.14797 2.5 4.71408V3.14265C2.5 2.70875 2.87277 2.35693 3.33333 2.35693H16.6667C17.1272 2.35693 17.5 2.70875 17.5 3.14265V18.8569C17.5 19.2908 17.1272 19.6426 16.6667 19.6426Z"
        fill={fillColor}
      />
      <path
        d="M19.1667 17.2857H17.5C17.0394 17.2857 16.6667 16.9339 16.6667 16.5C16.6667 16.0661 17.0394 15.7143 17.5 15.7143H18.3333V1.57143H6.66667V2.35714C6.66667 2.79104 6.2939 3.14286 5.83333 3.14286C5.37277 3.14286 5 2.79104 5 2.35714V0.785714C5 0.351816 5.37277 0 5.83333 0H19.1667C19.6272 0 20 0.351816 20 0.785714V16.5C20 16.9339 19.6272 17.2857 19.1667 17.2857Z"
        fill={fillColor}
      />
      <path
        d="M10.834 11.7858H4.16732C3.70675 11.7858 3.33398 11.434 3.33398 11.0001C3.33398 10.5662 3.70675 10.2144 4.16732 10.2144H10.834C11.2946 10.2144 11.6673 10.5662 11.6673 11.0001C11.6673 11.434 11.2946 11.7858 10.834 11.7858Z"
        fill={fillColor}
      />
      <path
        d="M10.834 16.5001H4.16732C3.70675 16.5001 3.33398 16.1483 3.33398 15.7144C3.33398 15.2805 3.70675 14.9287 4.16732 14.9287H10.834C11.2946 14.9287 11.6673 15.2805 11.6673 15.7144C11.6673 16.1483 11.2946 16.5001 10.834 16.5001Z"
        fill={fillColor}
      />
      <path
        d="M9.99944 13.3569C9.78627 13.3569 9.5731 13.2801 9.41035 13.1267C9.08483 12.8198 9.08483 12.3225 9.41035 12.0156L10.4877 10.9998L9.41015 9.98381C9.08464 9.67689 9.08464 9.17969 9.41015 8.87277C9.73567 8.56585 10.263 8.56585 10.5885 8.87277L12.2552 10.4442C12.5807 10.7511 12.5807 11.2483 12.2552 11.5552L10.5885 13.1267C10.4258 13.2801 10.2126 13.3569 9.99944 13.3569Z"
        fill={fillColor}
      />
      <path
        d="M4.9999 18.0713C4.78673 18.0713 4.57356 17.9945 4.41081 17.841L2.74414 16.2696C2.41862 15.9627 2.41862 15.4655 2.74414 15.1586L4.41081 13.5871C4.73633 13.2802 5.26366 13.2802 5.58919 13.5871C5.91471 13.894 5.91471 14.3912 5.58919 14.6982L4.51162 15.7142L5.58919 16.7302C5.91471 17.0371 5.91471 17.5343 5.58919 17.8412C5.42624 17.9945 5.21307 18.0713 4.9999 18.0713V18.0713Z"
        fill={fillColor}
      />
    </svg>
  );
};
