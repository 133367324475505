import React from "react";
import "./style.scss";

function ThreeDot({ isAnimation }) {
  return (
    <div
      className={["loader-dot", isAnimation && "loader-dot-animated"].join(" ")}
    />
  );
}

export default ThreeDot;
