import { Box, Stack } from "@mui/material";
import React from "react";
import "./style.scss";
import images from "../../constant/images";

function BackBtn({ handleBack }) {
  return (
    <Stack className="back-con" onClick={handleBack}>
      <Box component="img" src={images.backIcon}/>
    </Stack>
  );
}

export default BackBtn;
