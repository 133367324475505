import React from "react";
export const SnopIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64974 16.4527V16.266H1.59286V1.51699H14.7755V10.4551C14.849 10.4551 14.9225 10.4318 14.996 10.4318H16.1232C16.1967 10.4318 16.2702 10.4318 16.3437 10.4551V0.746868C16.3437 0.326885 16.0007 0 15.5595 0H0.784185C0.343218 0 0 0.32671 0 0.746868V17.0128C0 17.4328 0.343034 17.7596 0.784185 17.7596H8.64966C8.64966 17.6896 8.62522 17.6197 8.62522 17.5497V16.4526L8.64974 16.4527Z"
        fill={fillColor}
      />
      <path
        d="M11.9326 3.24414H4.41016C3.96919 3.24414 3.62598 3.57085 3.62598 3.99101C3.62598 4.41099 3.96901 4.73788 4.41016 4.73788H11.9326C12.3736 4.73788 12.7168 4.41117 12.7168 3.99101C12.7166 3.59414 12.3736 3.24414 11.9324 3.24414H11.9326Z"
        fill={fillColor}
      />
      <path
        d="M11.9326 6.51172H4.41016C3.96919 6.51172 3.62598 6.83843 3.62598 7.25859C3.62598 7.67857 3.96901 8.00546 4.41016 8.00546H11.9326C12.3736 8.00546 12.7168 7.67875 12.7168 7.25859C12.7166 6.83844 12.3736 6.51172 11.9324 6.51172H11.9326Z"
        fill={fillColor}
      />
      <path
        d="M11.9326 9.75586H4.41016C3.96919 9.75586 3.62598 10.0826 3.62598 10.5027C3.62598 10.9227 3.96901 11.2496 4.41016 11.2496H11.9326C12.3736 11.2496 12.7168 10.9229 12.7168 10.5027C12.7166 10.106 12.3736 9.75586 11.9324 9.75586H11.9326Z"
        fill={fillColor}
      />
      <path
        d="M4.41016 13.0213C3.96919 13.0213 3.62598 13.348 3.62598 13.7682C3.62598 14.1881 3.96901 14.515 4.41016 14.515H9.72732C9.60477 14.0016 9.72732 13.4414 10.0459 12.9982L4.41021 12.998L4.41016 13.0213Z"
        fill={fillColor}
      />
      <path
        d="M20.5091 15.9625L19.5291 15.8226C19.4066 15.5426 19.3821 15.4726 19.2596 15.1691L19.8477 14.399C19.9947 14.189 19.9947 13.9088 19.7986 13.7223L18.99 12.9522C18.794 12.7655 18.5 12.7422 18.2795 12.9055L17.4709 13.4656C17.1769 13.3489 17.1035 13.3256 16.7848 13.2089L16.6379 12.2755C16.5888 12.0188 16.3683 11.832 16.0988 11.832H14.9471C14.6776 11.832 14.4571 12.0187 14.4081 12.2755L14.2611 13.2089C13.9671 13.3256 13.8936 13.3489 13.575 13.4656L12.7664 12.9055C12.5459 12.742 12.2518 12.7655 12.0559 12.9522L11.2473 13.7223C11.0513 13.909 11.0268 14.189 11.1982 14.399L11.7864 15.1691C11.6638 15.4491 11.6394 15.5191 11.5168 15.8226L10.5368 15.9625C10.3406 15.986 10.1445 16.2193 10.1445 16.4527V17.5495C10.1445 17.8063 10.3406 18.0162 10.6101 18.063L11.5902 18.203C11.7127 18.483 11.7372 18.553 11.8597 18.8564L11.2716 19.6265C11.1246 19.8365 11.1246 20.1167 11.3206 20.3032L12.1292 21.0733C12.3253 21.26 12.6193 21.2833 12.8397 21.1201L13.6483 20.5599C13.9423 20.6766 14.0158 20.6999 14.3344 20.8166L14.4814 21.75C14.5305 22.0068 14.751 22.1935 15.0205 22.1935H16.1233C16.3928 22.1935 16.6133 22.0068 16.6623 21.75L16.8093 20.8166C17.1033 20.6999 17.1768 20.6766 17.4954 20.5599L18.304 21.1201C18.5245 21.26 18.8187 21.26 19.0145 21.0733L19.8231 20.3032C20.0191 20.1165 20.0436 19.8365 19.8722 19.6265L19.284 18.8564C19.4066 18.5764 19.431 18.5064 19.5536 18.203L20.5336 18.063C20.8032 18.0162 20.9992 17.8062 20.9992 17.5495V16.4527C20.9748 16.2193 20.7787 15.986 20.5092 15.9625H20.5091ZM17.5688 16.4994L15.3881 18.553C15.2655 18.6697 15.0941 18.7397 14.9225 18.7397C14.7508 18.7397 14.5794 18.6697 14.4569 18.553L13.3787 17.5261C13.1091 17.2694 13.1091 16.8727 13.3787 16.6159C13.6482 16.3592 14.0648 16.3592 14.3343 16.6159L14.9224 17.1761L16.6132 15.5658C16.8827 15.3091 17.2992 15.3091 17.5688 15.5658C17.8139 15.8458 17.8139 16.2426 17.5688 16.4993L17.5688 16.4994Z"
        fill={fillColor}
      />
    </svg>
  );
};
