import React from "react";
export const DetailedSummaryIcon = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_574_70)">
        <path
          d="M17.5477 15.0131H6.5419C6.5419 14.309 6.53925 13.6169 6.54765 12.9247C6.5481 12.8774 6.62288 12.807 6.6773 12.7884C8.63572 12.1126 9.8844 10.7761 10.3866 8.7674C10.9552 6.49402 9.83573 3.96042 7.77642 2.81067C7.58394 2.70313 7.38439 2.60666 7.18217 2.51859C6.97996 2.43008 6.77066 2.35883 6.55252 2.27519V0.103149H13.5774V4.0653H17.5482V15.0131H17.5477ZM10.1003 10.0539V11.02H15.0645V10.0539H10.1003ZM10.6079 9.03558H15.0641V8.06861H10.6079V9.03558ZM11.1101 6.07271V7.03924H15.0672V6.07271H11.1101Z"
          fill={color}
        />
        <path
          d="M5.54679 17.0732H4.55209V12.0821C4.04058 11.9082 3.5366 11.7989 3.09146 11.5741C1.51269 10.7753 0.635689 9.46754 0.580379 7.69955C0.524184 5.89262 1.3326 4.49593 2.91402 3.62765C5.68705 2.10483 9.08354 3.8622 9.49947 6.99855C9.81275 9.35911 8.1322 11.6059 5.7667 11.9755C5.58794 12.0034 5.54192 12.0613 5.5428 12.2357C5.54944 13.755 5.54679 15.2738 5.54679 16.7931V17.0737V17.0732ZM4.55873 9.03517H5.52466V5.08496H4.55873V9.03517ZM6.56007 6.07451V9.03119H7.52512V6.07451H6.56007ZM3.53173 9.04004V7.07733H2.57243V9.04004H3.53173Z"
          fill={color}
        />
        <path
          d="M17.1526 3.0629H14.5889V0.483276C15.4402 1.34005 16.3008 2.20612 17.1526 3.0629Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_574_70">
          <rect
            width="16.97"
            height="16.97"
            fill="white"
            transform="translate(0.577637 0.103149)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
