import React from "react";
export const MerketingEventsIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M61,7.9h-7.3v-5c0-0.6-0.4-1-1-1h-9.4c-0.6,0-1,0.4-1,1v5H31.7v-5c0-0.6-0.4-1-1-1h-9.4c-0.6,0-1,0.4-1,1v5H13
       c-0.6,0-1,0.4-1,1v5c0,1.3,2,1.3,2,0c0,0,0-4,0-4c14.6,0,31.4,0,46,0v6H35c-0.6,0-1,0.4-1,1s0.4,1,1,1h25v27.7h-9.4
       c-0.6,0-1,0.4-1,1v9H14v-6c0-0.6-0.4-1-1-1s-1,0.4-1,1v7c0,0.6,0.4,1,1,1h37.6c0.3,0,0.5-0.1,0.7-0.3c0,0,0,0,0,0l10.4-10
       c0.2-0.2,0.3-0.5,0.3-0.7c0,0,0-29.7,0-29.7v-8C62,8.4,61.6,7.9,61,7.9z M22.3,7.9v-4h7.4v4H22.3z M44.3,7.9v-4h7.4v4H44.3z
        M51.6,54.2v-6.7h6.9L51.6,54.2z"
          />
          <path
            d="M15.4,37l-0.2-1.1c0.1,0,12.6,3.1,12.7,3.1c2.1,0.4,3-2.2,3.5-7.1c5.7-0.4,5.7-8.7,0-9.1c-0.5-5-1.4-7.7-3.7-7.1
       l-17.3,4.2c0,0,0,0,0,0l-7.7,1.9c-0.4,0.1-0.8,0.5-0.8,1v9.2c0,0.5,0.3,0.9,0.8,1l2.8,0.7l2.3,8.6c0.2,0.6,0.6,1.2,1.2,1.5
       c0.6,0.3,1.2,0.4,1.9,0.2C14,43.1,15.9,40.1,15.4,37z M33.8,27.3c0,1.2-1,2.4-2.2,2.6c0.1-1.7,0.1-3.4,0-5.1
       C32.8,25,33.8,26,33.8,27.3z M28.2,17.6c1.2,1.5,1.7,9.7,1.3,13c-0.2,3.5-1,6.1-1.3,6.4l-13.9-3.4c0,0-2.6-0.6-2.6-0.6V21.6
       L28.2,17.6z M4,23.5l5.7-1.4v10.4l-0.1,0L4,31.1V23.5z M10.4,42C10.4,42,10.4,42,10.4,42c-0.3,0-0.5,0-0.6-0.3c0,0-2-7.6-2-7.6
       l2.5,0.6l0.1,0c0,0,0,0,0,0l2.7,0.7l0.3,1.9C13.8,39.4,12.5,41.5,10.4,42z"
          />
          <path d="M27,47.1c-1.3,0-1.3,2,0,2c0,0,14,0,14,0c1.3,0,1.3-2,0-2C41,47.1,27,47.1,27,47.1z" />
          <path d="M21.3,43c-1.3,0-1.3,2,0,2c0,0,19.7,0,19.7,0c1.3,0,1.3-2,0-2C41,43,21.3,43,21.3,43z" />
          <path d="M41,51.1H31c-1.3,0-1.3,2,0,2c0,0,10,0,10,0C42.3,53.1,42.3,51.2,41,51.1z" />
          <path d="M50,33.9c0.6,0,1-0.4,1-1v-8c0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1H50z M43,25.9h6v6h-6V25.9z" />
        </g>
      </g>
    </svg>
  );
};
