import React from "react";
export const CollaborationIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2344 2.23438C13.2344 3.46845 12.2341 4.46876 11 4.46876C9.76593 4.46876 8.76562 3.46845 8.76562 2.23438C8.76562 1.00031 9.76593 0 11 0C12.2341 0 13.2344 1.00031 13.2344 2.23438Z"
        fill={fillColor}
      />
      <path
        d="M9.31562 11.9967V7.87169C9.31562 6.87487 10.2094 6.04986 11.2406 6.1874C11.7219 6.25608 12.1 6.49679 12.375 6.87487H14.3C13.9906 5.87804 13.2689 5.08739 12.3063 4.6748C11.9283 4.88101 11.4814 4.98419 11.0001 4.98419C10.5532 4.98419 10.1063 4.88101 9.72821 4.6748C8.45618 5.25925 7.5625 6.53095 7.5625 7.97472V11.9623C7.83739 12.0309 7.94057 11.9966 9.31562 11.9966V11.9967Z"
        fill={fillColor}
      />
      <path
        d="M14.4374 14.025V11.0688C14.4374 10.5875 14.0594 10.2095 13.5781 10.2095C13.0969 10.2095 12.7188 10.5875 12.7188 11.0688V14.0938C12.7188 15.0907 11.825 15.9157 10.7938 15.7781C10.3125 15.7094 9.93444 15.4687 9.6594 15.0907H7.73438C8.04376 16.0875 8.80007 16.9125 9.72807 17.2907C10.1061 17.0845 10.553 16.9813 11.0343 16.9813C11.4812 16.9813 11.9281 17.0845 12.3062 17.2907C13.5438 16.7405 14.4374 15.4687 14.4374 14.025H14.4374Z"
        fill={fillColor}
      />
      <path
        d="M13.2344 19.7651C13.2344 20.9992 12.2341 21.9995 11 21.9995C9.76593 21.9995 8.76562 20.9992 8.76562 19.7651C8.76562 18.5311 9.76593 17.5308 11 17.5308C12.2341 17.5308 13.2344 18.5311 13.2344 19.7651Z"
        fill={fillColor}
      />
      <path
        d="M17.2902 9.72805C16.7401 8.45614 15.4683 7.5625 14.0246 7.5625H10.0371C9.96839 7.80305 10.0027 7.90623 10.0027 9.28131H14.1278C15.1246 9.28131 15.9496 10.1751 15.8121 11.2063C15.7434 11.6876 15.5027 12.0657 15.1246 12.3407V14.2657C16.0871 13.9907 16.9121 13.2689 17.3247 12.272C17.1185 11.894 17.0153 11.4471 17.0153 10.9658C16.9809 10.5531 17.0841 10.1062 17.2903 9.72809L17.2902 9.72805Z"
        fill={fillColor}
      />
      <path
        d="M22 11C22 12.2341 20.9997 13.2344 19.7656 13.2344C18.5316 13.2344 17.5312 12.2341 17.5312 11C17.5312 9.76593 18.5316 8.76562 19.7656 8.76562C20.9997 8.76562 22 9.76593 22 11Z"
        fill={fillColor}
      />
      <path
        d="M4.70932 12.2717C5.25924 13.5436 6.53115 14.4029 7.97487 14.4029H8.55932H8.73118H8.79986H11.9624C12.0311 14.1279 11.9967 14.0592 11.9967 12.6841H7.87169C6.87487 12.6841 6.04986 11.7903 6.1874 10.7591C6.25608 10.2778 6.49679 9.89977 6.87487 9.62473V7.69971C5.91236 8.00909 5.08739 8.73089 4.6748 9.6934C4.88101 10.0715 4.98419 10.5184 4.98419 10.9996C5.01853 11.4467 4.91535 11.8936 4.70914 12.2717L4.70932 12.2717Z"
        fill={fillColor}
      />
      <path
        d="M4.46876 11C4.46876 12.2341 3.46845 13.2344 2.23438 13.2344C1.00031 13.2344 0 12.2341 0 11C0 9.76593 1.00031 8.76562 2.23438 8.76562C3.46845 8.76562 4.46876 9.76593 4.46876 11Z"
        fill={fillColor}
      />
    </svg>
  );
};
