import React, {useState} from "react";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,isValidPhoneNumber
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { phoneInputValidateMsg } from "../../constant/constant";

export const convertLocalNumber = (num) => {
  let localNumber = formatPhoneNumber(num);
  localNumber = localNumber.replace(/^0+/, "");
  localNumber = localNumber.replaceAll(" ", "");
  localNumber = localNumber.replaceAll("-", "");
  localNumber = localNumber.replaceAll("(", "");
  localNumber = localNumber.replaceAll(")", "");
  return localNumber;
};

export const getPhoneCountryCode = (num) => {
  const interNational = formatPhoneNumberIntl(num);
  const localNumberArray = interNational.split(" ");
  return localNumberArray[0];
};

const CustomPhoneInput = ({ placeholder, value, onChange, className }) => {
  const [isValid, setIsValid] = useState(true);
  const handleChange = (val) => {
    const valid = val ? isValidPhoneNumber(val) : false;
    setIsValid(valid);
    onChange(val, valid);
  };

  return (
    <>
      <PhoneInput
        placeholder={placeholder}
        value={value}
        withCountryCallingCode
        onChange={handleChange}
        international
        defaultCountry="IN"
        className={className}
      />
      <span className="bu-helper-text-phone-number">{phoneInputValidateMsg}</span>
      {!isValid && (
        <span className="phone-number-error-msg">*Invalid phone number </span>
      )}
    </>
  );
};

export default CustomPhoneInput;
