import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import LoaderGif from "../../assets/Loader.gif";
export default function ProcessingLoader() {
  return (
    <div>
      <Backdrop
        sx={{
          backdropFilter: "blur(2px)",
          backgroundColor: "transparent",
          opacity: 1, zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={true}
      >
        <img src={LoaderGif} alt="loader" />
      </Backdrop>
    </div>
  );
}
