import React from "react";
export const AnalyticsIcon = ({ color }) => {
  let fillColor = color;
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.88888 15.6442C4.88888 15.1691 4.69991 14.7128 4.36414 14.3763C4.02767 14.0406 3.57135 13.8516 3.09629 13.8516H1.79259C1.31753 13.8516 0.861188 14.0405 0.52474 14.3763C0.188995 14.7128 0 15.1691 0 15.6442V20.2071C0 20.6822 0.188972 21.1385 0.52474 21.475C0.861216 21.8107 1.31753 21.9997 1.79259 21.9997H3.09629C3.57135 21.9997 4.0277 21.8107 4.36414 21.475C4.69989 21.1385 4.88888 20.6822 4.88888 20.2071V15.6442Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.593 11.5701C10.593 11.0943 10.404 10.6389 10.0682 10.3026C9.73195 9.9663 9.27564 9.77734 8.8004 9.77734H7.49669C7.02145 9.77734 6.5651 9.96632 6.22884 10.3026C5.8931 10.6389 5.7041 11.0943 5.7041 11.5701V20.2067C5.7041 20.6825 5.89307 21.1379 6.22884 21.4742C6.56514 21.8107 7.02145 21.9995 7.49669 21.9995H8.8004C9.27564 21.9995 9.73198 21.8107 10.0682 21.4742C10.404 21.1379 10.593 20.6825 10.593 20.2067V11.5701ZM8.96336 11.5701V20.2067C8.96336 20.25 8.94644 20.2914 8.91571 20.3224C8.88515 20.3524 8.8435 20.37 8.8004 20.37H7.49669C7.45359 20.37 7.41194 20.3524 7.38138 20.3224C7.35064 20.2914 7.33373 20.25 7.33373 20.2067V11.5701C7.33373 11.5268 7.35064 11.4854 7.38138 11.4544C7.41194 11.4244 7.45359 11.4068 7.49669 11.4068H8.8004C8.8435 11.4068 8.88515 11.4244 8.91571 11.4544C8.94644 11.4854 8.96336 11.5268 8.96336 11.5701Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2961 13.1998C16.2961 12.7248 16.1071 12.2684 15.7714 11.932C15.4349 11.5962 14.9786 11.4072 14.5035 11.4072H13.1998C12.7248 11.4072 12.2684 11.5962 11.932 11.932C11.5962 12.2684 11.4072 12.7248 11.4072 13.1998V20.2072C11.4072 20.6823 11.5962 21.1386 11.932 21.4751C12.2684 21.8108 12.7248 21.9998 13.1998 21.9998H14.5035C14.9786 21.9998 15.4349 21.8108 15.7714 21.4751C16.1071 21.1386 16.2961 20.6823 16.2961 20.2072V13.1998Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0002 7.49609C22.0002 7.02066 21.8112 6.56469 21.4755 6.22856C21.1392 5.89245 20.6829 5.70312 20.2076 5.70312H18.9039C18.4287 5.70312 17.9723 5.89246 17.6361 6.22856C17.3003 6.56468 17.1113 7.02066 17.1113 7.49609V20.2072C17.1113 20.6826 17.3003 21.1386 17.6361 21.4747C17.9724 21.8108 18.4287 22.0002 18.9039 22.0002H20.2076C20.6829 22.0002 21.1392 21.8108 21.4755 21.4747C21.8112 21.1386 22.0002 20.6826 22.0002 20.2072V7.49609ZM20.3706 7.49609V20.2072C20.3706 20.2499 20.3537 20.2916 20.3229 20.3221C20.2924 20.3527 20.2507 20.3698 20.2076 20.3698H18.9039C18.8608 20.3698 18.8192 20.3527 18.7886 20.3221C18.7579 20.2916 18.741 20.2499 18.741 20.2072V7.49609C18.741 7.45335 18.7579 7.4117 18.7886 7.38114C18.8192 7.35058 18.8608 7.33349 18.9039 7.33349H20.2076C20.2507 7.33349 20.2924 7.35059 20.3229 7.38114C20.3537 7.41169 20.3706 7.45335 20.3706 7.49609Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6587 2.78094L14.5957 5.84394C14.365 5.75337 14.1142 5.70371 13.8518 5.70371C13.2196 5.70371 12.6548 5.99217 12.2809 6.44431L10.1671 5.84065C10.0344 4.84415 9.18046 4.07408 8.14811 4.07408C7.02376 4.07408 6.11108 4.98677 6.11108 6.11112C6.11108 6.23334 6.12163 6.3532 6.14291 6.46887L3.13866 8.47173C2.82092 8.26712 2.44281 8.14817 2.03704 8.14817C0.912685 8.14817 0 9.06086 0 10.1852C0 11.3096 0.912685 12.2222 2.03704 12.2222C3.16139 12.2222 4.07407 11.3096 4.07407 10.1852C4.07407 10.063 4.06352 9.94313 4.04224 9.82745L7.04648 7.82459C7.36422 8.02921 7.74234 8.14816 8.14811 8.14816C8.78032 8.14816 9.34505 7.8597 9.71898 7.40756L11.8328 8.01122C11.9655 9.00771 12.8194 9.77778 13.8518 9.77778C14.9761 9.77778 15.8888 8.8651 15.8888 7.74075C15.8888 7.4783 15.8391 7.22751 15.7486 6.99685L18.8116 3.93384C19.0422 4.02442 19.293 4.07407 19.5555 4.07407C20.6798 4.07407 21.5925 3.16139 21.5925 2.03704C21.5925 0.912685 20.6798 0 19.5555 0C18.4311 0 17.5184 0.912685 17.5184 2.03704C17.5184 2.29948 17.5681 2.55028 17.6587 2.78094Z"
        fill={fillColor}
      />
    </svg>
  );
};
