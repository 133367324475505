import React from "react";
export const EquivalenceIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_574_67)">
        <path
          d="M9.06652 11.1668C7.70665 11.1668 6.34624 11.1596 4.98637 11.1735C4.74649 11.1757 4.65831 11.0906 4.59697 10.8715C3.92169 8.46866 3.23765 6.06857 2.55634 3.66736C2.49062 3.43603 2.426 3.20469 2.36411 2.97225C2.2721 2.62802 2.3548 2.42227 2.66095 2.256C4.25741 1.38738 5.96067 0.877446 7.76252 0.708394C9.72428 0.524328 11.6461 0.730638 13.5065 1.39517C14.1747 1.63429 14.816 1.95293 15.4606 2.256C15.7865 2.40948 15.8604 2.62747 15.7574 2.99338C15.0586 5.46632 14.3576 7.93871 13.6549 10.4105C13.5848 10.6574 13.5651 10.9933 13.3986 11.1179C13.2305 11.2441 12.909 11.1652 12.6543 11.1657C11.4588 11.1679 10.2626 11.1668 9.06707 11.1668H9.06652ZM9.00957 2.97002C7.84905 2.97781 6.67484 3.23083 5.59592 3.86033C5.27937 4.04495 5.17969 4.31187 5.2832 4.65887C5.54499 5.53527 5.80678 6.41223 6.07514 7.28641C6.26463 7.90423 6.5423 8.02879 7.15022 7.82804C8.42082 7.40931 9.69854 7.40819 10.9691 7.82526C11.5924 8.0299 11.8728 7.902 12.0628 7.27139C12.3082 6.45728 12.5229 5.63259 12.8011 4.83071C12.9884 4.29018 12.8066 3.97933 12.3301 3.73743C11.306 3.21748 10.2227 2.97892 9.01011 2.97002H9.00957Z"
          fill={color}
        />
        <path
          d="M4.40638 16.716V15.2357H13.5717V16.7005C13.6949 16.7066 13.7913 16.7155 13.8871 16.7155C14.8729 16.7166 15.8582 16.7155 16.844 16.7166C17.2581 16.7166 17.366 16.8239 17.367 17.241C17.3692 18.1213 17.3692 19.0016 17.367 19.8819C17.366 20.2967 17.2575 20.4029 16.8407 20.4046C16.459 20.4063 16.0773 20.4046 15.6474 20.4046C15.6474 20.7394 15.6594 21.0508 15.6446 21.3605C15.62 21.8727 15.1391 22.1524 14.707 21.9122C14.4753 21.7837 14.3855 21.5679 14.3833 21.3099C14.3811 21.0235 14.3833 20.7371 14.3833 20.428H3.65279C3.65279 20.7121 3.65498 20.9785 3.65279 21.2454C3.64841 21.7159 3.4151 21.995 3.02899 21.9972C2.63466 21.9995 2.39095 21.7103 2.38712 21.2309C2.38493 20.9724 2.38712 20.7138 2.38712 20.4052C2.25568 20.4052 2.14176 20.4052 2.02839 20.4052C0.620326 20.4001 0.764364 20.557 0.761078 19.105C0.759982 18.4844 0.759435 17.8633 0.761078 17.2427C0.762721 16.8234 0.868422 16.7177 1.28191 16.7171C2.21296 16.716 3.14345 16.7171 4.07449 16.7171C4.17308 16.7171 4.27166 16.7171 4.40638 16.7171V16.716Z"
          fill={color}
        />
        <path
          d="M9.06733 12.4481C11.4207 12.4481 13.774 12.4481 16.1274 12.4481C16.5984 12.4481 16.6641 12.5153 16.6646 12.9925C16.6646 13.0575 16.6646 13.122 16.6646 13.1871C16.6635 13.7643 16.5179 13.915 15.9559 13.9156C11.3588 13.9156 6.76163 13.9156 2.16447 13.9156C1.61515 13.9156 1.46454 13.7621 1.4629 13.2082C1.46144 12.7015 1.70661 12.4481 2.19842 12.4481C4.48769 12.4481 6.77751 12.4481 9.06678 12.4481H9.06733Z"
          fill={color}
        />
        <path
          d="M6.63867 4.76228C8.26252 4.08608 9.85132 4.08774 11.4872 4.75728C11.3152 5.3295 11.1471 5.88893 10.9746 6.46337C10.6411 6.39886 10.3327 6.33881 10.0041 6.27541C10.0633 6.1147 10.118 5.98013 10.1613 5.84277C10.2736 5.48798 10.1235 5.156 9.79655 5.02698C9.47342 4.89964 9.13551 5.04589 8.9849 5.38622C8.88413 5.61366 8.80253 5.85056 8.69244 6.07355C8.6552 6.14918 8.56374 6.23204 8.48597 6.24594C8.05605 6.32157 7.62284 6.37662 7.15348 6.44391C6.98973 5.90895 6.8205 5.35619 6.63867 4.76228Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_574_67">
          <rect
            width="16.6104"
            height="21.3562"
            fill="white"
            transform="translate(0.757812 0.64386)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
