import React from "react";
import "./style.scss";

const CustomFieldValidator = ({
  validator,
  field,
  inputValue,
  validations,
  options,
  customMessage,
}) => {
  let validationMessage = validator.message(
    field,
    inputValue,
    validations,
    options || {}
  );
  return (
    <div className="custom-error">
      {customMessage ? validationMessage && customMessage : validationMessage}
    </div>
  );
};

export default CustomFieldValidator;
