import React from "react";
export const EntitiesDataIcon = ({ color }) => {
  let fillColor = { color };
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.32715 3.89102H3.17715V4.04102V4.84738V4.99738H3.32715H14.9146H15.0646V4.84738V4.04102V3.89102H14.9146H3.32715Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.3"
      />
      <path
        d="M3.32715 6.10244H3.17715V6.25244V7.05881V7.20881H3.32715H14.9146H15.0646V7.05881V6.25244V6.10244H14.9146H3.32715Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.3"
      />
      <path
        d="M3.32715 8.35977H3.17715V8.50977V9.31613V9.46613H3.32715H9.10935H9.25935V9.31613V8.50977V8.35977H9.10935H3.32715Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.3"
      />
      <path
        d="M3.32715 10.5946H3.17715V10.7446V11.551V11.701H3.32715H7.51982H7.66982V11.551V10.7446V10.5946H7.51982H3.32715Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.3"
      />
      <path
        d="M22.9678 16.2594C22.8631 16.1547 22.7119 16.0543 22.5394 16.0542M22.9678 16.2594L22.8617 16.3655M22.9678 16.2594C22.9678 16.2594 22.9677 16.2594 22.9677 16.2593L22.8617 16.3655M22.9678 16.2594C23.0896 16.381 23.1564 16.5655 23.1253 16.7341M22.5394 16.0542L22.8617 16.3655M22.5394 16.0542H15.9739C15.7506 16.0542 15.5701 16.1823 15.4575 16.3515L15.4572 16.3513L15.4523 16.3598L12.3667 21.725C12.3664 21.7254 12.3662 21.7258 12.3659 21.7263C12.2806 21.8693 12.2754 22.0111 12.2948 22.1363M22.5394 16.0542L12.2948 22.1363M22.8617 16.3655C22.954 16.4576 22.9999 16.5958 22.977 16.711L23.1259 16.7289C23.1257 16.7306 23.1255 16.7324 23.1253 16.7341M23.1253 16.7341C22.6763 20.4462 19.5271 23.2652 15.7665 23.2652H1H0.85V23.1152V1V0.85H1H17.2178H17.3678V1V8.11193C20.5539 8.81379 22.964 11.5623 23.1498 14.952L23.1502 14.9602H23.15C23.15 15.0945 23.1229 15.2567 22.9909 15.3888C22.8715 15.5081 22.7185 15.5709 22.5623 15.5709H15.9508C15.7754 15.5709 15.5536 15.4856 15.4506 15.286L12.0901 9.62383C12.09 9.62367 12.0899 9.62352 12.0898 9.62336C11.9946 9.46446 11.9985 9.30686 12.0255 9.17178L12.0282 9.15818L12.0334 9.14531C12.0816 9.025 12.1686 8.86116 12.339 8.7889C13.3966 8.22624 14.5705 7.94541 15.7664 7.94541H15.7707C15.9236 7.94541 16.0947 7.94541 16.2615 7.95539V1.95634H1.95624V22.1586L12.2948 22.1363M23.1253 16.7341L12.2948 22.1363M16.2897 14.3725L13.427 9.54991C14.1683 9.25435 14.9462 9.09778 15.7431 9.09771C18.8588 9.12001 21.4511 11.3759 21.9066 14.3725H16.2897ZM21.8247 17.2527C21.1965 20.0307 18.7141 22.0749 15.7987 22.0898H15.7667C15.0612 22.0898 14.3758 21.9751 13.7277 21.7462L16.3141 17.2527H21.8247Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.3"
      />
      <path
        d="M11.212 9.51472C11.0439 9.48095 10.8629 9.54692 10.747 9.6339L10.747 9.6339L10.7463 9.63442C8.88892 11.045 7.83047 13.1856 7.83047 15.513C7.83047 17.9359 9.03004 20.1923 11.0032 21.6016L11.0031 21.6016L11.3437 21.6986L11.3437 21.6986L11.3459 21.6986C11.3703 21.6986 11.4356 21.6987 11.5065 21.6688C11.6603 21.6327 11.7798 21.5358 11.8639 21.3955L11.864 21.3955L11.8655 21.3929L14.9502 16.0064C15.072 15.8199 15.0718 15.5967 14.9495 15.4103L11.611 9.79246L11.6107 9.79194C11.5171 9.63577 11.3629 9.54485 11.212 9.51472ZM11.212 9.51472C11.212 9.51471 11.2119 9.5147 11.2119 9.51469L11.1825 9.6618L11.2121 9.51474C11.2121 9.51473 11.212 9.51472 11.212 9.51472ZM9.00577 15.513C9.00577 13.7753 9.71874 12.1641 10.9627 11.0022L13.7502 15.7213L11.1647 20.2131C9.80713 19.0438 9.00577 17.3203 9.00577 15.513Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="0.3"
      />
    </svg>
  );
};
