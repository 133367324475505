import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { get } from "lodash";
import { default as React, useState } from "react";
import OtpInput from 'react-otp-input';
import { Link, useLocation } from "react-router-dom";
import companyLogo from "../../assets/3SC-logo.svg";
import { processLogin } from "../../helper/commonHelper";
import {
    loginMFA
} from "../../utils/ApiHandler";
import "../Login/Login.scss";
import ProcessingLoader from "./../../components/ProcessingLoader/index";

const OTP = () => {
    const location = useLocation();
    const stateObj = get(location, "state", "");
    const oob_code = stateObj?.oob_code ?? "";
    const mfa_token = stateObj?.mfa_token ?? "";
    const email = stateObj?.email ?? "";
    const [loader, setLoader] = useState(false);
    const [otp, setOtp] = useState('');

    const validateOTP = async (otp) => {
        setOtp(otp);
        if (otp.length === 6) {
            setLoader(true);
            const res = await loginMFA(
                otp,
                oob_code,
                mfa_token,
                email
            )
            processLogin(res, setLoader);
        }

    }

    if (mfa_token !== "") {
        return (
            <div className="login-container">
                {loader && <ProcessingLoader />}
                <div className="login">
                    <div className="logo-container">
                        <div className="logo-container-logo">
                            <img src={companyLogo} alt="logo" className="logo-img" />
                        </div>
                    </div>
                    <div className="otp-input-container">
                        <OtpInput
                            value={otp}
                            onChange={otp => validateOTP(otp)}
                            numInputs={6}
                            // renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} />}
                            shouldAutoFocus={true}
                            separator={<span style={{ width: "8px" }}></span>}
                            inputType="tel"
                            inputStyle={"otp-input"}
                            focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                            }}
                        />
                    </div>
                    <Link to={"/"} className="remove-underline">
                        <div className="forgot-password-back">
                            <KeyboardBackspaceIcon />
                            <span>Back to Login</span>
                        </div>
                    </Link>
                </div>
            </div>
        )
    } else {
        window.location.replace("/")
    }
}

export default OTP