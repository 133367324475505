import React, { useState, useEffect } from "react";
import "./style.scss";

const TypingAnimation = ({
  text,
  typingSpeed = 300,
  deletingSpeed = 150,
  pauseTime = 1000,
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let typingTimeout;

    const handleTyping = () => {
      const fullText = text;

      if (isDeleting) {
        setDisplayedText(fullText.substring(0, displayedText.length - 1));
        if (displayedText === fullText) {
          setDisplayedText(fullText.toUpperCase());
        }
        if (displayedText === "") {
          setIsDeleting(false);
          setIndex((prev) => prev + 1);
        }
      } else {
        setDisplayedText(fullText.substring(0, displayedText.length + 1));
        if (displayedText === fullText) {
          typingTimeout = setTimeout(() => setIsDeleting(true), pauseTime);
        }
      }
    };

    typingTimeout = setTimeout(
      handleTyping,
      isDeleting ? deletingSpeed : typingSpeed
    );

    return () => clearTimeout(typingTimeout);
  }, [
    displayedText,
    isDeleting,
    text,
    typingSpeed,
    deletingSpeed,
    pauseTime,
    index,
  ]);

  return (
    <span className={`typing-text ${isDeleting ? "deleting" : ""}`}>
      {displayedText}
    </span>
  );
};

export default TypingAnimation;
