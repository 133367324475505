import { ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Fullscreen from "fullscreen-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { matchPathName } from "../../../helper/commonHelper";
import {
  APPBAR_MENU,
  CarbonXMenu,
  EDMMenu,
  IBPMenu,
  RiskAIMenu,
  snopMenu
} from "../../../utils/Constants";
import EndPoint from "../../../utils/EndPoints";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { customTheme } from "./components/common";
import "./layout.scss";

export default function Layout(props) {
  const { IBP, EDM, CX, ID, DTRM } = APPBAR_MENU;
  const [mainMenu, setMainMenu] = useState(""); // localStorage.getItem("mainMenu")?.slice(1, -1) ?? IBP
  const [open, setOpen] = useState(true);
  const [isEnter, setIsEnter] = useState(false);
  const location = useLocation();
  const isMyProfile = location.pathname === EndPoint.MY_PROFILE;
  const layoutClass = isEnter
    ? "layout-content-main-app-scroll layout-content-main-app"
    : "layout-content-main-app";

  useEffect(() => {
    let path = location.pathname;
    if (
      IBPMenu.some((el) => matchPathName(el.subMenuPaths, path)) ||
      snopMenu.some((el) => el.childPath === path)
    ) {
      setMainMenu(IBP);
    } else if (EDMMenu.some((el) => matchPathName(el.subMenuPaths, path))) {
      setMainMenu(EDM);
    } else if (CarbonXMenu.some((el) => matchPathName(el.subMenuPaths, path))) {
      setMainMenu(CX);
    } else if (RiskAIMenu.some((el) => matchPathName(el.subMenuPaths, path))) {
      setMainMenu(DTRM);
    } else {
      setMainMenu(ID);
    }
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }} className="main-container">
      <CssBaseline />
      <ThemeProvider theme={customTheme}>
        {/* Header */}
        <Header
          open={open}
          setOpen={setOpen}
          setIsEnter={setIsEnter}
          isEnter={isEnter}
          mainMenu={mainMenu}
          setMainMenu={setMainMenu}
          isMyProfile={isMyProfile}
        />
        {/* Side Bar */}
        <Sidebar
          open={open}
          setOpen={setOpen}
          mainMenu={mainMenu}
          setMainMenu={setMainMenu}
          isMyProfile={isMyProfile}
        />
      </ThemeProvider>

      {/* Main Container */}
      <Box
        component="main"
        sx={{ flexGrow: 1, overflow: "auto", maxHeight: "100vh" }}
        className="layout-content-main"
      >
        {/* <DrawerHeader /> */}
        <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
          <div className={layoutClass}>{props.children}</div>
        </Fullscreen>
      </Box>
    </Box>
  );
}
